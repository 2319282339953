let config = {
  "countryCode": "DE",
  "search": "Suche",
  "production": "Produktion",
  "Production": "Produktion",
  "Receipt": "Annahme",
  "Finance": "Finanzen",
  "IT": "IT",
  "Repair": "Reparatur",
  "Support": "Support",
  "Dispatch": "Versand",
  "Customer": "Kunden",
  "Devices": "Geräte",
  "address": "Adresse",
  "Warehouse": "Lager",
  "absorb Customer": "Kunden zusammenführen",
  "Product": "Produkt",
  "My Tickets": "Meine Tickets",
  "Team Tickets": "Team Tickets",
  "Create new ticket": "Erstelle ein Ticket",
  "Amount": "Menge",
  "Email already exists": "Email existiert bereits",
  "ID": "Id",
  "Article Number": "Artikelnummer",
  "Stock Count": "Stückzahl",
  "low Stock Count": "Niedriger Stückzahl-Alarm",
  "connected products with the customer": "Verbundene Produkte mit dem Kunden",
  "Name": "Name",
  "Create a new Ticket/Email in Zammad": "Erstelle ein Ticket/Email in Zammad",
  "Add received accessories": "Empfangenes Zubehör hinzufügen",
  "Version": "Version",
  "Variant": "Variante",
  "Price": "Preis",
  "reduced_price": "Reduzierter Preis",
  "Announced": "Angekündigt",
  "Go to Zammad organization": "Gehe zur Zammad-Organisation",
  "deposit": "Pfand",
  "save": "Speichern",
  "last known customer": "letzter bekannter Kunden",
  "equals": "ist gleich",
  "notEquals": "ist nicht gleich",
  "greaterThan": "ist größer als",
  "lessThan": "ist kleiner als",
  "greaterThanOrEqual": "ist größer als oder gleich",
  "lessThanOrEqual": "ist kleiner als oder gleich",
  "Order not found in the System. Please Check if you inputs match your SHIFTphone8 order.": "Bestellung nicht im System gefunden. Überprüfe, ob die Eingaben mit deiner SHIFTphone8-Bestellung übereinstimmen.",
  "Email dose not match with the order mismatch. Please Check if you inputs match your SHIFTphone8 order.": "Email stimmt nicht mit der Bestellung überein. Überprüfe, ob die Eingaben mit deiner SHIFTphone8-Bestellung übereinstimmen.",
  "contains": "enthält",
  "notContains": "enthält nicht",
  "startsWith": "startet mit",
  "productInstance": "Produktinstanz",
  "endsWith": "endet mit",
  "RequireOneOf": "Setze einer dieser Felder: {0}",
  "device age in Month": "Gerätealter in Monaten",
  "payed price": "Bezahlter Preis",
  "description": "Beschreibung",
  "accepted value": "Anrechnungswert",
  "no Magento order": "Keine Magento-Bestellung",
  "old Magento order": "Alte Magento-Bestellung",
  "Order is not payed!": "Bestellung ist nicht bezahlt!",
  "device price": "Gerätepreis",
  "pledge price": "Pfandpreis",
  "Workflow Actions": "Workflow-Aktionen",
  "Dispatch Tickets": "Versand Tickets",
  "Finance Tickets": "Finanz Tickets",
  "Create a repair Child Ticket": "Erstelle ein Reparatur Sub-Ticket",
  "Create a wrong delivery Child Ticket": "Erstelle eine Falschlieferung",
  "Create a return Ticket": "Erstelle ein Rückgabe Ticket",
  "Receipt Tickets": "Annahme Tickets",
  "Confirmation": "Bestätigung",
  "no shipment address": "Keine Lieferadresse",
  "edit address": "Adresse bearbeiten",
  "create address": "Adresse hinzufügen",
  "Repair Tickets": "Reparatur Tickets",
  "only for SL-refurbishment": "Nur für SL-Refurbishment",
  "only for guarantee or goodwill": "Nur für Garantie oder Kulanz",
  "total price": "Gesamtpreis",
  "offer accepted": "Angebot angenommen",
  "magento order number": "Magento-Bestellnummer",
  "surrenderValue": "Rückkauf Wert",
  "display views": "Anzeigearten",
  "Missing child ticket": "Es fehlt das subticket",
  "Child Ticket": "Subticket",
  "in wrong state": "im falschen Status",
  "Customer Created with ID": "Kunde angelegt mit ID",
  "Please use the protocol for further nodes! Only initial information should be entered here.": "Bitte verwenden Sie das Protokoll für die weiteren Kommentare! Nur Initialinformationen sollten hier eingegeben werden.",
  "Do you want to batch create multiple product instances?": "Möchten Sie mehrere Produktinstanzen als Batch erstellen?",
  "Do you want to close the Test?": "Möchtest du den Test schließen?",
  "Invalid postcode or country not supported": "Ungültige Postleitzahl oder Land nicht unterstützt",
  "expect:": "erwartet:",
  "Create a sub-ticket": "Erstelle ein Unterticket",
  "Create a product instance and add it to the ticket": "Erstelle ein Produktinstanz und füge es dem Ticket hinzu",
  "Please confirm": "Bitte bestätigen",
  "Create a new customer": "Erstelle einen Kunde",
  "Delete the Ticket completely": "Lösche das Ticket komplett",
  "Create a new Inquiry for a Team": "Erstelle eine Anfrage für ein Team",
  "Go back to open state": "Gehe zurück zum offenen Zustand",
  "subject": "Betreff",
  "Add Product Instance from Customer to the Ticket": "Füge eine Produktinstanz vom Kunden zum Ticket hinzu",
  "create an new Organization": "Erstelle eine neue Organisation",
  "Show more": "Mehr anzeigen",
  "Show less": "Weniger anzeigen",
  "note": "Notiz",
  "Active": "Aktiv",
  "Inactive": "Inaktiv",
  "create a new Item": "Erstelle ein neues Item",
  "internal-productInstance": "interne Produktinstanz",
  "absorb another Customer into this Customer": "Einem bestehenden Kunden dieses hinzufügen",
  "remove product instance from ticket": "Entferne eine Produktinstanz vom Ticket",
  "Missing child ticket with product": "Es fehlt ein Unterticket mit Produkt",
  "Invalid Payment ID": "Ungültige Zahlungs-ID",
  "Blocked by sub type: guarantee": "Gesperrt durch Untertyp: Garantie",
  "checked": "markiert",
  "Child Ticket in wrong state inProgress": "Unterticket in falschen Status in Bearbeitung",
  "export": "Export",
  "create Ticket": "Ticket erstellen",
  "Missing order ID": "Es fehlt die Bestellnummer",
  "Everything checked": "Alle ausgewählten Tickets wurden überprüft",
  "Answer the Inquiry": "Antworten auf die Anfrage",
  "'": "'",
  "$vuetify": {
    "open": "Öffnen",
    "badge": "Hinweis",
    "close": "Schließen",
    "fileInput": {
      counter: '{0} Dateien',
      counterSize: '{0} Dateien ({1} gesamt)',
    },
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "page": "Seite",
        "currentPage": "aktuelle Seite",
        "next": "weiter",
        "previous": "zurück"
      }
    },
    "noDataText": "Keine Daten vorhanden",
    "dataFooter": {
      "itemsPerPageAll": "Alle Artikel pro Seite",
      "itemsPerPageText": "Artikel pro Seite",
      "pageText": "{0}-{1} von {2}",
      "prevPage": "Vorherige Seite",
      "firstPage": "Erste Seite",
      "nextPage": "Nächste Seite",
      "lastPage": "Letzte Seite"
    },
    "datePicker": {
      "header": "Datumsauswahl",
      "title": "Datum auswählen"
    },
    "stepper": {
      "next": "Weiter",
      "prev": "Zurück"
    },
    "input": {
      "clear": "Löschen",
    }
  },
  "__typename": "Artikeltyp",
  "Error while preparing action": "Fehler beim Vorbereiten der Aktion",
  "No mobile devices connected": "Kein Handy mit deinem Account verbunden.",
  "Please connect your mobile devices!": "Bitte verbinde dein Handy!",
  "Address Type": "Address Type",
  "prefix": "Präfix",
  "counter": "Zähler",
  "suffix": "Suffix",
  "first name": "Vorname",
  "last name": "Nachname",
  "middle name": "Mittlerer Name",
  "street and no": "Straße und Hausnummer",
  "street and house number": "Straße und Hausnummer",
  "postal code": "PLZ",
  "postcode": "PLZ",
  "country_id": "Land ID",
  "city": "Stadt",
  "region": "Region",
  "country": "Land",
  "email": "E-Mail",
  "not specified": "nicht angegeben",
  "phone": "Telefonnummer",
  "company": "Firma",
  "add": "hinzufügen",
  "firstname": "Vorname",
  "lastname": "Nachname",
  "street": "Straße",
  "Login for your mobile device": "Login für dein Handy",
  "Do you really want to delete this address?": "Willst du wirklich die Adresse löschen?",
  "This customer has no assigned addresses yet": "Dieser Kunde hat noch keine Adressen zugewiesen",
  "Select a address to copy": "Wähle eine Addresse zu kopieren",
  "Add File": "File hinzufügen",
  "Error uploading picture": "Fehler beim Hochladen des Bildes",
  "Take Picture": "Bild machen",
  "picture name": "Bildname",
  "upload pictures": "Bildr hochladen",
  "upload picture": "Bild hochladen",
  "Barcode Detector": "Barcode Detector",
  "Scan QR-Code, Barcode or something else": "Scan QR-Code, Barcode oder etwas anderes",
  "Close": "Schließen",
  "Application Overview": "Anwendungsübersicht",
  "Actions": "Aktionen",
  "gender": "Geschlecht",
  "Gender": "Geschlecht",
  "create": "erstellen",
  "Saved": "gespeichert",
  "Customer property": "Kunden Eigenschaft",
  "Detailes are shown": "Detailes werdem angezeigt",
  "Detailes are hidden": "Detailes sind versteckt",
  "cancel": "abbrechen",
  "Cancel": "Abbrechen",
  "Addresses": "Adressen",
  "Attachments": "Anhänge",
  "Related Products": "Verbundene Artikel",
  "Related Tickets": "Verbundene Tickets",
  "Company": "Firma",
  "title": "title",
  "main email": "Haupt-E-Mail",
  "email2": "E-Mail 2",
  "make main E-Mail": "zur hauptadresse machen",
  "email3": "E-Mail 3",
  "address_text": "address_text",
  "Same shipment address": "gleiche Versandadresse",
  "Next": "Next",
  "Size": "Größe",
  "Revocation": "Widerruf",
  "Upgrade": "Upgrade",
  "upgrade": "Upgrade",
  "Return other": "Rückgabe sonstiges",
  "Return used device": "Rückgabe eines Gebrauchten Geräts",
  "No History": "Keine History",
  "Show Migration Data": "Zeige die Migration-Daten",
  "show migration raw data": "Zeige die Rohen Migration-Daten",
  "global search": "Globale suche",
  "Searching in the following fields:": "Suche in den folgenden Feldern:",
  "entity id": "entity id",
  "Magento Order": "Magento-Bestellung",
  "open order in Magento": "öffnene Bestellung in Magento",
  "opens in new window": "in einem neuen Fenster öffnen",
  "tax amount": "Steuer kosten",
  "grand total": "grand total",
  "shipping incl tax": "versand inkl. MwSt.",
  "payment method": "Bezahlmethode",
  "tax name": "Stuer name",
  "tax rate": "Steuer",
  "Order Items": "Bestellpositionen",
  "quantity ordered": "Bestellmenge",
  "base price": "Basispreis",
  "No record": "Kein Eintrag",
  "base price incl tax": "basispreis inkl. MwSt.",
  "Order Comments": "Bestellungskommentare",
  "Please set Name (*)": "Bitte geben Sie den Namen (*) ein",
  "address_type": "Adresstyp",
  "status": "Stand",
  ", created at": ", erstellt am",
  "Email": "E-Mail",
  "Street": "Straße",
  "Scan Product-Instance 2 Desktop": "Geräteausfruf in Desktop-PC",
  "City": "Stadt",
  "Postal Code": "PLZ",
  "Country": "Land",
  "Magento Order Number": "Magento-Bestellnummer",
  "Submit Number": "Nummer Hinzufügen",
  "Add Products / Tracking Code": "Hinzufügen von Produkten / Tracking-Code",
  "Restart All": "Alles neustarten",
  "Create Shipment Ticket": "Erstelle ein Versandticket",
  "Cancel Request": "Anfrage Abbrechen",
  "Back": "zurück",
  "No Request!": "Derzeit keine Anfrage!",
  "Waiting for requests from Desktop PC!": "Warten auf Auftrag vom Desktop-PC!",
  "Scan Barcode 4 Desktop": "Text eingabe in Desktop-PC",
  "Inspect Product Instance": "Geräteaufruf",
  "Product Instance": "Produktinstanz",
  "Test a device": "Test für ein Gerät erstellen",
  "Create Shipping Ticket": "Versand Ticket erstellen",
  "Add": "hinzufügen",
  "Continue": "Weiter",
  "Are you sure you want to delete this filter?": "Bist du sicher, dass du den Filter löschen möchtest?",
  "Closed": "Schließen",
  "updated: ": "updated: ",
  "Open Ticket": "Öffne das Ticket",
  "Are you sure you want to delete this item?": "Bist du sicher, dass du löschen möchtest?",
  "No Device": "Kein Gerät",
  "Upgrade Offer": "Upgradeangebot",
  "Add device 2 upgrade": "Füge ein Gerät zum Upgrade hinzu",
  "upgrade price": "Upgrade-Kosten",
  "No Order Number": " Keine Bestellnummer",
  "Old Device": "Altes Gerät",
  "deviceAge": "Gerät ist vor {month} Monaten und {days} Tagen verkauft worden",
  "Add old device to upgrade": "Altes Gerät zum Upgrade hinzufügen",
  "Add new device": "Neues Gerät hinzufügen",
  "Open existing Test": "Öffne einen bestehenden Test",
  "Upgrade Order": "Upgrade",
  "Billing Address": "Rechnungsadresse",
  "billing": "Rechnung",
  "Amount of Products": "Anzahl von Produkten",
  "Select Product": "Produkt auswählen",
  "Save": "Speichern",
  "processing": "Verarbeitung",
  "Repair Cost": "Reparaturkosten",
  "Total: before tax": "Gesammt: vor Steuern",
  "before tax": "vor Steuern",
  "Total: after tax (19%)": "Gesammt: nach Steuern (19%)",
  "after tax (19%)": "nach Steuern (19%)",
  "Add Products to Ticket": "Füge ein Produkt zum Ticket hinzu",
  "Create a PDF document from the template": "PDF-Dokument aus Vorlage hinzufügen",
  "Open in a new browser tab": "In einem neuen Browser-Tab öffnen",
  "Input SN/IMEI": "SN/IMEI eingeben",
  "Variant:": "Variante:",
  "Serial number": "Seriennummer",
  "IMEI 1": "IMEI 1",
  "IMEI 2": "IMEI 2",
  "Description": "Beschreibung",
  "Device property": "Geräteeigenschaften",
  "State": "Status",
  "quality": "Qualität",
  "details are shown": "Details werden gezeigt",
  "details are hidden": "Details sind versteckt",
  "state": "Status",
  "internal-ticket": "Interne Ticket",
  "internal-customer": "Interne Kunde",
  "Should be": "sollte sein",
  "but is": "aber ist",
  "serialnumber": "Seriennummer",
  "Produced at": "Produktionsdatum",
  "Productname": "Produktname",
  "SN": "SN",
  "Logout": "Logout",
  "Additional Products": "Zusätzliche Produkte",
  "Still open Tests": "unbearbeitete Tests",
  "Change Profile Config": "Ändere Profil-Konfiguration",
  "My Settings": "Meine Einstellungen",
  "This Ticket is connected to a stolen product": "Dises Ticket ist mit einem gestohlenen Produkt verbunden",
  "Are you sure you want to delete this relation?": "Bist du sicher, dass du diese Verbindung löschen möchtest?",
  "Product Code": "Produkt-Code",
  "Date ID": "Datum Kennung",
  "Herstellungsdatum": "Herstellungsdatum",
  "Product SN": "Produt SN",
  "My Profile": "Mein Profil",
  "Username": "Username",
  "First name": "Vorname",
  "Last name": "Nachname",
  "Not specified": "Nicht spezifiziert",
  "Teams": "Teams",
  "New Protocol": "Neues Protokoll",
  "update": "update",
  "Move tasks to this Ticket": "Verschiebe Aufgaben zu diesem Ticket",
  "Move Task to the Child Ticket": "Verschiebe Aufgabe zu diesem Sub Ticket",
  "product ID": "Produkt ID",
  "product Name": "Produktname",
  "product name": "Produktname",
  "Select the reason of the return": "Wähle den Grund der Rückgabe",
  "Ticket detailes are hidden": "Ticket Details sind versteckt",
  "Ticket detailes are shown": "Ticket Details werden angezeigt",
  "Input not valid. Please check!": "Eingabe ungültig. Bitte überprüfe!",
  "no due date": "kein Fälligkeitsdatum",
  "store amount": "Lager Anzahl",
  "last change": "letzte Änderung",
  "book in": "book in",
  "book out": "book out",
  "store": "store",
  "open": "öffnen",
  "Magento order shipment payment": "Magento Order Versand zahlung",
  "": "",
  "Start repair": "Reparatur starten",
  "Active columns": "Sichtbare Felder",
  "select date": "wähle ein Datum",
  "select start date": "wähle ein Startdatum",
  "select end date": "wähle ein Enddatum",
  "Add Filter": "Filter hinzuzufügen",
  "No filters are set": "Keine Filter sind gesetzt",
  "filter name": "Filter Name",
  "Save Filter": "Filter speichern",
  "Stored Filter": "Gespeicherte Filter",
  "Delete Filter": "Filter löschen",
  "Reload Filter": "Filter neue laden",
  "Search in following fields:": "Suche in folgenden Feldern:",
  "Tasks": "Aufgaben",
  "Task description": "Aufgaben Beschreibung",
  "Store Property": "Store Property",
  "Template Name": "Template Name",
  "Please load a Ticket for the preview": "Bitte lade ein Ticket für die Vorschau",
  "add PDF to Ticket": "füge das PDF zum Ticket hinzufügen",
  "select": "Auswählen",
  "add directly": "PDF direkt anhängen",
  "No address available for selection": "Für die Auswahl steht keine Adresse zur Verfügung",
  "Finished Workflow steps": "Beendete Workflow Schritte",
  "No product instance is selected!": "Keine Produktinstanz ausgewählt!",
  "Select a test": "Wähle einen Test aus",
  "Quality Result": "Qualitäts Ergebnis",
  "Set Quality": "Setze Qualität",
  "Success": "Erfolgreich",
  "Error": "Fehler",
  "Undo": "Rückgängig",
  "Close Reason": "Close Reason",
  "Close Ticket": "Schließe Ticket",
  "Ticket": "Ticket",
  "ticket name": "Ticket Name",
  "ticket typ": "Ticket-Typ",
  "ticket id": "Ticket-ID",
  "id": "ID",
  "Missing Customer connection": "Kundenverbindung fehlt",
  "Missing customer": "Kunde fehlt",
  "ticket": "Ticket",
  "customer": "Kunde",
  "Ticket property": "Ticket Eigenschaften",
  "Ticket Type": "Ticket Type",
  "Created at": "Erstellt am",
  "Please select a ticket": "Bitte wähle ein Ticket",
  "All Tasks": "Alle Aufgaben",
  "Workflow actions": "Workflow Aktionen",
  "Workflow preview": "Workflow Vorschau",
  "GoTo: ": "GoTo: ",
  "Workflow": "Workflow",
  "User course": "Benuter Arbeitsabläufen",
  "touch date is undefined": "touch date is undefined",
  "is undefined": "ist nicht definiert",
  "ticket type is undefined": "ticket type ist nicht definiert",
  "Options": "Optionen",
  "Delete": "Löschen",
  "Really delete this Object?": "Willst du wirlisch löschen?",
  "Yes": "Ja",
  "No": "Nein",
  "Add new element from input text": "Füge ein neues Element hinzu",
  "select the due date": "wähle ein Fällichkeitsdatum",
  "Clear": "Löschen",
  "Close Date Picker": "Schließe den Date Picker",
  "Invalid date Object": "Ungültiges Datum",
  "Invalid date": "Ungültiges Datum",
  "Need to be a string": "Muss eine Zeichenkette sein",
  "Invalid Email": "Email ist nicht gültig",
  "IMEI needs digits only": "IMEI darf nur Zahlen enthalten",
  "IMEI needs 15 digits": "IMEI braucht 15 Ziffern",
  "Add new Filter": "Filter hinzufügen",
  "Check sum is not valid": "Die Checksumme ist nicht gültig",
  "IMEI2 not next to IMEI1": "IMEI2 ist nicht neben IMEI1",
  "Order number must be a number": "Order number muss eine Zahl sein",
  "Order number must be 9 digits": "Order number muss 9 zeichen haben",
  "Number_to_small": "Nummer ist zu klein",
  "Value must only contain digits": "Dieser Parameter darf nur Zahlen enthalten",
  "Invalid Phone Number example: +49 1234 567890": "Ungültige Telefonnummer! Beispiel: +49 1234 567890",
  "This Parameter is required": "Dies ist ein Pflichtfeld",
  "Value must only contain letters": "der Wert darf nur Buchstaben enthalten",
  "Value must be a string": "der Wert muss eine Zeichenkette sein",
  "Tracking Code needs digits only": "Tracking-Code darf nur Zahlen enthalten",
  "Tracking Code needs to equal 20 digits": "Der Tracking-Code muss 20 Zeichen haben",
  "ticket-no": "Ticketnummer",
  "Ticket-no": "Ticketnummer",
  "support-no": "Supportnummer",
  "hardware_definition-no": "Hardwaredefinitionnummer",
  "Teamplates": "Teamplates",
  "Magento Order Device": "Magento Bestellnummer Gerät",
  "Magento Order Repair": "Magento Bestellnummer Reparatur",
  "invoice_number Repair": "Rechnungsnummer Reparatur",
  "quality_assurance-no": "Qualitätssicherungsnummer",
  "repair-no": "Reparaturnummer",
  "due date is today": "Fälligkeitsdatum ist heute",
  "return-no": "Rückgabe-Nummer",
  "is not in expected state: ": "ist nicht in erwartetem Status: ",
  "delivery-no": "Lieferungsnummer",
  "shipment-no": "Versandnummer",
  "priority": "Priorität",
  "Team": "Team",
  "Delete Address": "Adresse löschen",
  "Magento Order Number (additional payment)": "Magento Bestellnummer (nachzahlung)",
  "Creation Date": "Erstellungsdatum",
  "Sub type": "Untertyp",
  "Magento State": "Magento Status",
  "Magento Number": "Magento Nummer",
  "Protocols": "Protokolle",
  "Advance Device Deposit": "Vorauszahlung",
  "Edit Address": "Adresse bearbeiten",
  "Ticket Sub Type": "Ticket Sub Typ",
  "No Rows selected!": "Keine Zeilen selektiert",
  "Type": "Typ",
  "Delete table config": "Lösche die Tabellen Konfiguration",
  "Reload table config": "Lade die Tabellen Konfiguration neu",
  "Load a Table Config": "Lade eine Tabellen Konfiguration",
  "Save the current table config": "Erstelle eine neue Tabellen Konfiguration",
  "Update current table config": "Aktualisiere die aktuelle Tabellen Konfiguration",
  "Add a new Filter": "Füge ein neues Filter hinzu",
  "Select lins for mass update": "Wähle Zeilen für das Massenupdate aus",
  "Change column config": "Ändere die Spalten Konfiguration",
  "Export table to Excel": "Exportiere die Tabelle in Excel",
  "due date": "Fälligkeitsdatum",
  "not in": "nicht in",
  "Add a new protocol": "Füge ein neues Protokoll hinzu",
  "Add a new Address to the ticket": "Füge eine neue Adresse zum Ticket hinzu",
  "Add an existing ticket as a child": "Füge ein bestehendes Ticket als Sub Ticket hinzu",
  "sub type": "Untertyp",
  "Create a Ticket": "Erstelle ein Ticket",
  "Create a sub Ticket": "Erstelle ein Sub Ticket",
  "Create a product Instance": "Erstelle eine Produktinstanz",
  "Ticket State": "Ticket Status",
  "Select the diagnosis": "Wähle die Diagnose",
  "createdAt": "Erstellungsdatum",
  "updatedAt": "Änderungsdatum",
  "Templates": "Templates",
  "Missing child tickets": "Fehlende Subticket",
  "remove attachment from ticket": "Löschene Anhang vom Ticket",
  "Missing protocol entry. Please add a protocol": "Fehlende Protokolleinträge. Bitte füge ein Protokoll hinzu",
  "Return Reason": "Rückgabegrund",
  "Diagnosis": "Diagnose",
  "This customer has no assigned addresses yet.": "Dieser Kunde hat noch keine Adressen zugewiesen.",
  "female": "weiblich",
  "nonbinary": "nicht binär",
  "generate PDF": "generiere PDF",
  "generate": "Generieren",
  "Reload preview": "Neue Vorschau laden",
  "Load Ticket": "Ticket laden",
  "Back to edit view": "Zurück zur Bearbeitungsansicht",
  "Your Text": "Dein Text",
  "Add a new Address to the Customer": "Füge eine neue Adresse zum Kunden hinzu",
  "Add an existing ticket": "Füge ein bestehendes Ticket hinzu",
  "ready for next Step": "Bereit für den nächsten Schritt",
  "male": "männlich",
  "dr": "Dr.",
  "prof": "Prof.",
  "dipl-ing": "Dipl.-Ing.",
  "ceo": "CEO",
  "cto": "CTO",
  "cfo": "CFO",
  "manager": "Manager/in",
  "director": "Direktor/in",
  "assistant": "Assistent/in",
  "Shipping": "Versand",
  "Add Product Instance to the Ticket": "Füge eine neue Produkt Instanz zum Ticket hinzu",
  "create a Product Instance and add to the Ticket": "Erstelle eine neue Produkt Instanz und füge sie dem Ticket hinzu",
  "name": "Name",
  "Create a new Customer": "Erstelle einen neuen Kunden",
  "Connect a customer to this ticket": "Verbindung einen Kunden zu diesem Ticket",
  "Connect customer over the ProductInstance": "Verbindung einen Kunden über die Produkt Instanz",
  "Copy Address from Customer to Ticket": "Kopiere eine Adresse vom Kunden zum Ticket",
  "Add Product Instance to the Ticket by scanner": "Scanne eine Produkt Instanz und füge sie dem Ticket hinzu",
  "Add a picture with the smartphone": "Mache ein Bild mit dem Smartphone",
  "Add an attachment": "Füge ein Anhang hinzu",
  "key": "key",
  "User is disabled": "Benutzer ist deaktiviert",
  "last_hit": "letzte Benutzung",
  "Merge Customers": "Zusammenführen von Kunden",
  "All Tickets will be merged!": "Alle Tickets werden zusammengeführt!",
  "absorb customer": "Zusammenführen von Kunden",
  "Target Customer": "Zielkunde",
  "Customer to be absorbed": "Kunde, der zusammengeführt werden soll",
  "Show history": "Zeige die Verlaufshistorie",
  "Show history of the ticket": "Zeige die Verlaufshistorie des Tickets",
  "medium": "Normal",
  "stolen": "Gestohlen",
  "Show Config": "Zeige Konfiguration",
  "language": "Sprache",
  "Delete Ticket": "Lösche das Ticket",
  "on": "on",
  "Massupdate_success": "Fertig! Angepasste Objekte: {updated}, Erstellte Objekte {created}",
  "Update all selected rows": "Ändert die eine ausgewählte Zeile (Nur auf der aktuellen Tabellenseite) | Ändert die {count} ausgewählten Zeilen (Nur auf der aktuellen Tabellenseite)",
  "Update all filtered rows": "Ändert die eine gefilterte Zeile (Nur auf der aktuellen Tabellenseite) | Ändert die {count} gefilterten Zeilen (Nur auf der aktuellen Tabellenseite)",
  "day": "Tag",
  "delete": "löschen",
  "Created by": "Erstellt von",
  "Mass update": "Massenänderung",
  "Mass update Tickets": "Massenänderung von Tickets",
  "Are you shure you want to to the mass update?": "Du bist dabei {amount} Zeilen anzupassen. Bist du dir sicher?",
  "Product U. Name": "Produkt U. Name",
  "Add some amount of products to the ticket": "Füge eine Anzahl an Produkten zum Ticket hinzu",
  "There are still open Tasks/ToDos": "Es gibt immer noch offene Aufgaben/ToDos",
  "have passed since the due date": "nach Fälligkeitsdatum",
  "days": "Tage",
  "past": "vergangen",
  "Missing product": "Fehlende Produkte",
  "to go": "noch",
  "Quality": "Qualität",
  "SubState": "Unter Status",
  "Product Instance to Repair": "Produkt Instanz zu reparieren",
  "Product to Repair": "Produkt zu reparieren",
  "First Name": "Vorname",
  "Last Name": "Nachname",
  "Copy address for DHL Form": "Kopiere die Adresse für das DHL Formular",
  "Phone": "Telefonnummer",
  "Phone number": "Telefonnummer",
  "Update Date": "Änderungsdatum",
  "Missing Translations": "Fehlde Übersetzungen",
  "Missing attachment with:": "Benötige einen Anhang mit Schlüsselwort:",
  "Missing attachment": "Anhang fehlt",
  "until the due date": "bis zum Fälligkeitsdatum",
  "GoTo": "Gehe zu",
  "not empty": "nicht leer",
  "Name Only": "Nur Name",
  "Ticket Name": "Ticket Name",
  "Create a product instance": "Erstelle eine Produktinstanz",
  "Create a customer": "Lege einen Kunden an",
  "Create a template": "Erstelle ein Template",
  "Create a ticket": "Erstelle ein Ticket",
  "Missing product_instances": "Fehlende Produkt Instanzen",
  "missing product instance": "Fehlende Produkt Instanz",
  "high": "Hoch",
  "low": "Niedrig",
  "remove child ticket from ticket": "Lösche das Sub Ticket vom Ticket",
  "advance device deposit": "Vorgerät Pfand",
  "loanDevice": "Leihgerät",
  "Protocol": "Protokoll",
  "upgradeDifferencePrice": "Upgrade-Differenzpreis",
  "Related Product Instances": "Zugehörige Produkt Instanzen",
  "no": "Nr",
  "empty": "leer",
  "name not defined": "Name nicht definiert",
  "undefined": "nicht definiert",
  "User not found": "Benutzer nicht gefunden",
  "Parameter is missing:": "Parameter fehlt:",
  "Residual Order": "Rückkauf Auftrag",
  "Parent Tickets": "Super Tickets",
  "Child Tickets": "Sub Tickets",
  "Related Customer": "Zugehörige Kunden",
  "Repair parts": "Reparatur Teile",
  "Please set name": "Bitte gebe einen Namen an",
  "create_options_title": "Erstellungsoptionen",
  "create_options_description": "In dieser Aktionsliste können Tickets, Kunden, Produktinstanzen oder Templates für Formulare angelegt werden. Diese Objekte können später miteinander verknüpft oder als Ausgangspunkt für neue Anfragen verwendet werden.",
  "history_title": "Verlauf",
  "history_description": "Die Verlaufsliste bietet einen schnellen Überblick über die Tickets, die Sie zuletzt aufgerufen haben.",
  "notification_title": "Benachrichtigungen",
  "notification_description": "Benachrichtigungen halten Sie über Änderungen an Tickets, die Sie verfolgen, auf dem Laufenden.",
  "Are you sure you want to cancel this ticket?": "Sind Sie sicher, dass Sie dieses Ticket abbrechen möchten?",
  "Settings": "Einstellungen",
  "Translation": "Übersetzung",
  "No Magento Order Number found in the ticket": "Kein Magento-Bestellnummer für dieses Ticket gefunden",
  "ready": "bereit",
  "pin": "PIN",
  "Magento Order Loan Device": "Magento-Bestellnummer Leihgerät",
  "and": "und",
  "execute": "Ausführen",
  "muster": "Muster",
  "Not possible": "Nicht möglich",
  "possible": "möglich",
  "already done": "bereits erledigt",
  "other": "andere",
  "Finished": "Abgeschlossen",
  "Current": "Aktuell",
  "create a Ticket from Product Instance Scanner": "Erstelle ein Return Ticket mit dem Produkt Instanz Scanner",
  "or": "oder",
  "Set state open": "Setze den Status auf neu",
  "create a Ticket from Product Instance": "Erstelle ein Ticket aus einer Produkt Instanz",
  "\n": "\n",
  "Arrows": "Pfeile",
  "order info": "Bestellinformationen",
  "order id": "Bestellnummer",
  "order date": "Bestelldatum",
  "Package Checked": "Paket überprüft",
  "not required": "nicht erforderlich",
  "compensation type": "Vergütungsart",
  "Customer Contact Name": "Kunden Kontakt Name",
  "device access info": "Gerätezugriffsinformationen",
  "contact info": "Kontaktinformationen",
  "complete": "Abgeschlossen",
  "States": "Stati",
  "create inquiry": "Rückfrage erstellen",
  "Answer inquiry": "Beantworte die Rückfrage",
  "Question": "Frage",
  "Order number": "Bestellnummer",
  "Please enter your Order number": "Bitte geben Sie Ihren Ordnernummer ein",
  "Please enter your IMEI numbers": "Bitte geben Sie Ihre IMEI-Nummern ein",
  "task_open": "{count} ist offen | {count} sind offen",
  "task_closed": "{count} ist geschlossen | {count} sind geschlossen",
  "address type": "Typ der Adresse",
  "Please set Name": "Bitte gebe einen Namen an",
  "Update my Roles": "Aktuallisiere meine Rechte/Rollen",
  "Update Table Config": "Ändere die Tabellen Konfiguration",
  "back": "zurück",
  "Create new Table Config": "Erstelle eine neue Tabellen Konfiguration",
  "Table config saved successfully": "Tabellen Konfiguration erfolgreich gespeichert",
  "Magento-Order-Number post deposit": "Magento-Bestellung nachträgliches Pfand",
  "My Roles": "Meine Rechte/Rollen",
  "Show ticket raw data": "Zeige Ticket als roher Daten",
  "due_date is missing": "Fälligkeitsdatum fehlt",
  "Search for a customer in Magento based on the Magento Order Number": "Suche nach einem Kunden in Magento basierend auf der Magento-Bestellnummer",
  "Only PNG, JPEG, GIF, TXT, PDF, LibreOffice, and Microsoft Office files are allowed": "Nur PNG, JPEG, GIF, TXT, PDF, LibreOffice und Microsoft Office-Dateien sind erlaubt",
  "Each file must be smaller than": "Jede Datei muss kleiner sein als {maxSizeInMB}MB",
  "Order number must start with 9 digits followed by an optional hyphen and another digit": "Die Bestellnummer muss mit 9 Zahlen beginnen und eventuell einen optionalen Bindestrich und dann eine weitere Zahl folgen",
  "Value must only contain digits.": "Der Wert darf nur Zahlen enthalten.",
  "The number of digits does not match the country code": "Die Anzahl der Zahlen passt nicht zu der Ländercode",
  "The BIC code must be either 8 or 11 characters long": "Der BIC-Code muss entweder 8 oder 11 Zeichen lang sein",
  "Value must contain at least 20 letters": "Der Wert muss mindestens 20 Buchstaben enthalten",
  "support": "Support",
  "repair": "Reparatur",
  "book_in": "Buchung ein",
  "book_out": "Buchung aus",
  "production_order": "Produktionsauftrag",
  "product_order": "Produktauftrag",
  "testing": "Tests",
  "delivery": "Gesamte Lieferung",
  "shipment": "Lieferung",
  "quality_assurance": "Qualitätssicherung",
  "hardware_definition": "Hardware Definition",
  "special": "Sonderbehandlung",
  "return": "Rückgabe",
  "Order does not include debit": "Bestellung enthält  keine Pfandzahlung",
  "Order does not include deposit": "Bestellung enthält kein Pfand",
  "Ticket submitted successfully": "Ticket wurde erfolgreich gesendet",
  "Ticket submission failed": "Ticket anlegen fehlgeschlagen",
  "Order status retrieved successfully": "Bestellstatus wurde erfolgreich abgefragt",
  "Order status retrieval failed": "Bestellstatus abfrage fehlgeschlagen",
  "_ProtocolPanel": {
    "parentProtocol": "Super",
    "parentProtocol_toolTip": "Zeige zusätzlich alle Protokolleinträge vom Super-Ticket",
    "siblingProtocol": "Seiten",
    "siblingProtocol_toolTip": "Zeige zusätzlich alle Protokolleinträge vom Seiten-Ticket (Sub-Tickets vom Super-Ticket)",
    "childProtocol": "Sub",
    "childProtocol_toolTip": "Zeige zusätzlich alle Protokolleinträge vom Sub-Ticket",
  },
  "_ProtocolItem": {
    "parent": "Super-Ticket",
    "child": "Sub-Ticket",
    "sibling": "Seiten-Ticket"
  },
  "Computer says no! More then 1000 lines!": "Computer sagt nein! Mehr als 1000 Zeilen!",
  "Hide Details": "Details verstecken",
  "Statistics": "Statistik",
  "Customer Name": "Kundenname",
  "close ticket": "Ticket schließen",
  "Billing": "Rechnung",
  "remove some amount of products ti the ticket": "Einige Artikel aus dem Ticket entfernen",
  "Open": "Offen",
  "Ticket number": "Ticketnummer",
  "Customers": "Kunden",
  "Saved Team Tickets": "Gespeicherte Team Tickets",
  "Missing repair receipt": "Fehlende Reparaturbelegung",
  "sub-serialnumber": "Sub-Seriennummer",
  "Show Details": "Details anzeigen",
  "No valid tracking Code!": "Keine gültige Tracking-Code!",
  "Blocked by type: with costs": "Gesperrt von Typ: mit Kosten",
  "Missing Product to repair": "Fehlendes Produkt zum Reparieren",
  "closed": "Geschlossen",
  "Missing child ticket return": "Fehlende Unterticket zur Rückgabe",
  "Missing child ticket shipment": "Fehlendes Unterticket zur Lieferung",
  "mobile": "Mobile",
  "Please accept at least one new device offer!": "Bitte akzeptieren Sie mindestens einen neuen Geräteangebot!",
  "customer is missing": "Kunde fehlt",
  "magento_order_number_deposit is missing": "Magento-Bestellung Pfand fehlt",
  "InProgess": "In Bearbeitung",
  "Ticket Title": "Ticket Titel",
  "pending_payment": "Warte auf Bezahlung",
  "Require Role": "Benötigte Rechte",
  "software_update": "Software-Update",
  "magento_order_number is missing": "Magento-Bestellnummer fehlt",
  "landline": "Landline",
  "Order number must start with 9 digits followed by an optional hyphen and another digit 1": "Die Bestellnummer muss mit 9 Zahlen beginnen und eventuell einen optionalen Bindestrich und dann eine weitere Zahl 1 folgen",
  "Order": "Bestellung",
  "refund is missing": "Rückerstattung fehlt",
  "Are you sure you want to set the ticket state to open?": "Sind Sie sicher, dass Sie den Ticketstatus auf offen setzen möchten?",
  "ticket_support_update": "Support-Update",
  "Require Role ticket_support_update": "Benötigte Rechte ticket_support_update",
  "Check Product Instances in the delivery": "Überprüfen Sie die Produktinstanzen in der Lieferung",
  "Missing repairDiagnosis": "Fehlendes Reparaturdiagnose",
  "flash SHIFTphone 8 with version OTTER.SOS.6.0.G-RELEASE-20241128-QFIL": "Flash SHIFTphone 8 mit Version OTTER.SOS.6.0.G-RELEASE-20241128-QFIL",
  "flash SHIFTphone 8 with version OTTER.SOS.6.0.G-RELEASE-20240927-QFIL": "Flash SHIFTphone 8 mit Version OTTER.SOS.6.0.G-RELEASE-20240927-QFIL",
  "Child Ticket in wrong state open": "Ungültiger Unterticket in falscher Status offen",
  "Invalid MAC! e.g.": "Ungültige MAC-Adresse, z.B.:",
  "Blocked by sub type: goodwill": "Gesperrt von Untertyp: Kulanz",
  "Read": "Lesen",
  "Do you really want to delete this entity?": "Sind Sie sicher, dass Sie diese Entität löschen möchten?",
  "with sub type": "mit Untertyp",
  "does not exist": "existiert nicht",
  "Invalid MAC": "Ungültige MAC-Adresse",
  "payment_id is missing": "Zahlungs-ID fehlt",
  "tracking_code is missing": "Tracking-Code fehlt",
  "Create a template for creating forms": "Erstellen Sie eine Vorlage für die Erstellung von Formularen",
  "Value must only contain letters.": "Der Wert darf nur Buchstaben enthalten.",
  "Please add at least one old device order!": "Bitte fügen Sie mindestens eine alte Bestellung hinzu!",
  "repurchase": "Kaufen",
  "Child Ticket in wrong state preOrder": "Ungültiger Unterticket in falscher Status vorbestellen",

  "_statistic": {
    "_shipSP8_Overview": {
      "_input": {

      }
    }
  },
  "_orderStatus": {
    "priority": {
      "bstock_option": "SHIFTphone 8.0 B-Ware",
      "urgent": "Schnelle Solidaritätslieferung",
      "normal": "Normale Wartezeit",
      "wait": "Solidarisches Warten",
      "share": "SHIFTphone 8 share",
    }
  },
  "_validation": {
    "_file": {
      "InvalidFileExtension": "Ungültige Dateiendung {fileExtension}. Erlaubte Dateiendungen: {allowedExtensions}",
      "InvalidMissingExtension": "Es fehlt eine gültige Dateiendung für {fileName}.",
      "noFileName": "Es wurde kein Dateiname angegeben.",
      "fileToBig": "Die Datei ist zu groß. Die maximale Größe ist {maxSizeInMB}MB.",
      "totalSizeToBig": "Die Gesamtgröße der Dateien überschreitet die maximale Größe von {maxSizeInMB}MB."
    },
    "_minLength": {
      "Required_minimum_length": "Mindestens {digits} Zeichen",
      "Required_max_length": "Maximal {digits} Zeichen",
    },
    "_magento_order_number": {
      "Order not found in Magento": "Bestellung in Magento nicht gefunden"
    }
  },
  "_Product": {
    "sp8": "SHIFTphone 8",
    "book2": "SHIFTbook 2",
    "book1": "SHIFTbook 1",
    "6mq": "SHIFT6mq",
    "5me": "SHIFT5me",
    "sound BNO": "SHIFTsound BNO",
    "sound SP": "SHIFTsound SP",
    "hub": "SHIFThub",
    "pod": "SHIFTpod",
    "screen": "SHIFTscreen",
    "zubehör": "Zubehör",
    "6": "SHIFT6",
    "6m": "SHIFT6m",
    "4": "SHIFT4",
    "4_2": "SHIFT4.2",
    "5": "SHIFT5",
    "5_1": "SHIFT5.1",
    "5_2": "SHIFT5.2",
    "5_3": "SHIFT5.3",
    "7": "SHIFT7",
    "7+": "SHIFT7+",
  },
  "_dayOfWeek": {
    "Monday": "Montag",
    "Tuesday": "Dienstag",
    "Wednesday": "Mittwoch",
    "Thursday": "Donnerstag",
    "Friday": "Freitag",
    "Saturday": "Samstag",
  },
  "_Form": {
    "DeliveryState": {
      error: {
        "Customer name and Email do not match the order": "Name und E-Mail-Adresse passen nicht zur Bestellnummer.",
        "Preorder not found": "Vorbestellung nicht gefunden",
        "Missing order number": "Es wurde keine Bestellnummer angegeben",
        "Not Matched Workflow": "Unbekannter Bestellvorgang",
      },
      blockedBy: {
        "Blocked by loan device": "Damit wir dein SHIFTphone versenden können, musst du zuvor noch aktiv werden.<br><br>Du hast noch ein <b>{device}</b> als Leihgerät von uns. Wenn du uns ein <b>Pfand</b> hinterlegst, musst du dein jetziges Gerät erst dann zurückschicken, wenn dein SHIFTphone 8 bei dir eingetroffen ist und hast genug Zeit zur Datenübertragung. Weitere Infos zur Pfandoption (bitte gut durchlesen) findest du <a href=\"{link}\" target=\"_blank\">hier</a>.<br><br>Nach Bestellung und Bezahlung des Pfands kann es bis zu einer Woche dauern, bis sich der Status aktualisiert. Falls sich dieser danach noch nicht geändert haben sollte, kannst du dich gerne an unseren Support wenden. Wir empfehlen dir, dein Pfand spätestens dann zu hinterlegen, wenn nur noch <b>10 Blöcke</b> vor deiner Bestellung zu versenden sind. Eine Pfandhinterlegung führt jedoch nicht zu einer Veränderung der Versandreihenfolge.<br><br><b>Wenn du kein Pfand hinterlegst,</b> benötigen wir zuerst die Rücksendung deines Gerätes, bevor dein SHIFTphone 8.1 verschickt werden kann. Wir kontaktieren dich dann kurz vor Auslieferung bzgl. der Rücksendung deines jetzigen Gerätes. Die Bearbeitungszeit beträgt je nach Support-Aufkommen 2-4 Wochen.",
        "Blocked by update device": "Damit wir dein SHIFTphone versenden können, musst du zuvor noch aktiv werden.<br><br>Du hast noch ein <b>{device}</b>, für welches du ein Upgrade gebucht hast. Wenn du uns ein <b>Pfand</b> hinterlegst, musst du dein jetziges Gerät erst dann zurückschicken, wenn dein SHIFTphone 8 bei dir eingetroffen ist und hast genug Zeit zur Datenübertragung. Weitere Infos zur Pfandoption (bitte gut durchlesen) findest du <a href=\"{link}\" target=\"_blank\">hier</a>.<br><br>Nach Bestellung und Bezahlung des Pfands kann es bis zu einer Woche dauern, bis sich der Status aktualisiert. Falls sich dieser danach noch nicht geändert haben sollte, kannst du dich gerne an unseren Support wenden. Wir empfehlen dir, dein Pfand spätestens dann zu hinterlegen, wenn nur noch <b>10 Blöcke</b> vor deiner Bestellung zu versenden sind. Eine Pfandhinterlegung führt jedoch nicht zu einer Veränderung der Versandreihenfolge.<br><br><b>Wenn du kein Pfand hinterlegst,</b> benötigen wir zuerst die Rücksendung deines Gerätes, bevor dein SHIFTphone 8.1 verschickt werden kann. Wir kontaktieren dich dann kurz vor Auslieferung bzgl. der Rücksendung deines jetzigen Gerätes. Die Bearbeitungszeit beträgt je nach Support-Aufkommen 2-4 Wochen.",
        "Blocked by combination product": "Damit wir dein SHIFTphone versenden können, musst du zuvor noch aktiv werden.<br><br>Du hast noch ein <b>{device}</b> von einem Kombipaket von uns. Wenn du uns ein <b>Pfand</b> hinterlegst, musst du dein jetziges Gerät erst dann zurückschicken, wenn dein SHIFTphone 8 bei dir eingetroffen ist und hast genug Zeit zur Datenübertragung. Weitere Infos zur Pfandoption (bitte gut durchlesen) findest du <a href=\"{link}\" target=\"_blank\">hier</a>.<br><br>Nach Bestellung und Bezahlung des Pfands kann es bis zu einer Woche dauern, bis sich der Status aktualisiert. Falls sich dieser danach noch nicht geändert haben sollte, kannst du dich gerne an unseren Support wenden. Wir empfehlen dir, dein Pfand spätestens dann zu hinterlegen, wenn nur noch <b>10 Blöcke</b> vor deiner Bestellung zu versenden sind. Eine Pfandhinterlegung führt jedoch nicht zu einer Veränderung der Versandreihenfolge.<br><br><b>Wenn du kein Pfand hinterlegst,</b> benötigen wir zuerst die Rücksendung deines Gerätes, bevor dein SHIFTphone 8.1 verschickt werden kann. Wir kontaktieren dich dann kurz vor Auslieferung bzgl. der Rücksendung deines jetzigen Gerätes. Die Bearbeitungszeit beträgt je nach Support-Aufkommen 2-4 Wochen.",
      }
    },
    "_Callback": {
      "active": "Rückruf gewünscht",
      "callbackOptionalInfo": "Je nach Anliegen kann es vorkommen, dass deine Nachricht am besten von uns per E-Mail beantwortet werden kann. Behalte also bitte zum Telefon auch dein E-Mail-Postfach im Blick.",
      "chooseATime": "Wähle Zeitpunkte, zu denen ein Rückruf gut passen würde",
      "bestTime": "Bevorzugte Zeit",
      "goodTime": "Alternative Zeit",
      "phoneNumber": "Telefonnummer",
      "_priority": {
        "low": "Nicht Dringend - Rückmeldung innerhalb einer Woche",
        "medium": "Dringend - Rückmeldung spätestens in 3 Tagen",
        "high": "Sehr dringend - Rückmeldung möglichst in einem Tag"
      },
      "priority": "Dringlichkeit",
    },
    "_Contact": {
      "orderedWhere": "Wo bestellt?",
      "Support": "Support",
      "Shop": "Shop",
      "Question": "Fragen",
      "topic": "Thema",
      "topic_product": "SHIFTprodukt",
      "subTopic": "Unterthema",
      "firstName": "Vorname",
      "lastName": "Nachname",
      "email": "E-Mail",
      "phone": "Telefonnummer",
      "orderNumber": "Bestellnummer",
      "subject": "Betreff",
      "message": "Nachricht",
      "submit": "Absenden",
      "attachFile": "Anhang hinzufügen",
      "attachFileInstructions": "Screenshots und Fotos helfen uns deine Anfrage besser zu bearbeiten.",
      "addInvoice": "Bitte füge eine Rechnung zur Anfrage hinzu",
      "ticketCreated": {
        "title": "Danke für die Anfrage",
        "text": "Wir werden uns bei dir so schnell wie möglich melden. Deine Ticketnummer lautet: {ticketId}"
      },
      "errorCreatingTicket": {
        "title": "Es ist ein Fehler aufgetreten",
        "text": "Bitte versuche es später noch einmal. Fehlerbeschreibung: {errorMessage}"
      },
    },
    "_Support": {
      "text": "Wenn du ein Problem oder eine konkrete Frage zu deinem Gerät oder deinem SHIFT-Artikel hast, dann nutze dieses Formular.",
      "_subTopic": {
        "spareparts": "Ersatzteile",
        "camera": "Kamera",
        "mic-speaker": "Mikrofon oder Lautsprecher",
        "mobileData": "mobile Daten/SIM-Karte",
        "nfc": "NFC",
        "repairState": "Fragen zum Reparaturstand",
        "sensor": "Sensoren",
        "ShiftOS": "ShiftOS oder Fragen zu Einstellungen",
        "ShiftOS Light": "ShiftOS-Light oder alternatives OS",
        "softwareBug": "Softwarefehler",
        "other": "Sonstiges Thema",
        "update": "Upgrade auf ein neues SHIFT-Modell",
        "WLAN": "WLAN",
        "crash-restart": "Abstürze oder ungewollte Neustarts",
        "battery/charge": "Akku oder Fragen zur Aufladung",
        "apps": "Fragen zu Apps",
        "bluetooth": "Bluetooth",
        "display": "Display",
        "crash": "Abstürze",
        "display-touch": "Display / Touch",
        "sensor-camera-interface": "Sensoren / Kamera / Schnittstellen",
        "windows": "Softwarefragen - Windows",
        "ubuntu": "Softwarefragen - Ubuntu",
        "keys-pen": "SHIFTkeys / SHIFTpen",
        "ram-ssd": "RAM / SSD",
        "wlan-internet": "WLAN / Internet",
        "mic": "Mikrofon",
        "speaker": "Lautsprecher",
        "bumper": "Bumper",
        "holder": "Halterung",
        "cables-adapter": "Kabel / Adapter",
        "charger": "Ladegerät",
        "glass": "Panzerglass",
        "screwdriver": "Schraubendreher",
        "sleeve": "Shutzhülle"
      },
    },
    "_Question": {
      "text": "Nutze dieses Formular, wenn du Fragen zur SHIFT GmbH, unseren Produkten oder allgemeine Anfragen hast.",
      "_topic": {
        'SHIFT Gmbh': 'Fragen zur SHIFT GmbH',
        'product': 'Fragen zu Produkten',
        'advice': 'Beratung - Welches ist das richtige Modell für mich?',
        'other': 'Sonstige Fragen',
      }
    },
    "_Shop": {
      "text": "Wenn du eine Frage zu unserem Shop, deiner Bestellung oder deinem Benutzerkonto hast, dann nutze dieses Formular.",
      "_topic": {
        "deliveryTime": "Frage nach Lieferzeit",
        "deliveryTimeSP8": "Frage nach Lieferzeit des SHIFTphone8",
        "payment": "Fragen zur Zahlung",
        "deliveredOrder": "bereits versendete oder erhaltene Bestellungen",
        "cancle": "Widerruf oder Stornierung",
        "order": "Fragen zu einer offenen Bestellung",
        "other": "Sonstige Fragen zum Shop"
      }
    }
  },
  "_inquery": {
    "lastOwner": "Letzer Bearbeiter",
    "user": "Bearbeiter",
    "team": "Team",
  },
  "_zammad": {
    _customer: {
      ShureConnectCustomer: "Willst du das Zammd Ticket mit dem Kunden {name} verknüpfen?",
    },
    "connect": {
      "title": "Verbindung mit einem bestehenden Ticket",
      "subtitle": "Verbinden Sie das Zammad-Ticket mit einem bestehenden Ticket",
      "btn": "Verbinden",
      "noRelatedTickets": "Es gibt keine zugehörigen Tickets.",
      "dialog": {
        "title": "Mit Intra Kundenkontakt verbinden",
        "subtitle": "Verbinde den Zammad Kontakt mit dem Kontakt eines Kunden im Intra.",
        "zammadTitle": "Zammad Kontakt",
        "intraTitle": "Intra Kontakt",
        "intraContactList": "Liste möglicher Kontakte",
        "connectExistCintactBtnText": "Kontakt verbinden mit",
        "connectCreateNewCustomerBtnText": "Kontakt anlegen und verbinden",
      }
    },
    "create": {
      "title": "Ein neues Ticket erstellen",
      "subtitle": "Erstellen Sie ein neues Ticket und verknüpfen Sie es mit dem Zammad-Ticket",
      "btn": "Erstellen"
    },
    "info": {
      "moreThenOneCustomer": "Die E-Mail {email} wurde mehrfach vergeben.",
      "pleaseCheck": "Überprüfe die E-Mail-Adresse manuell und ordne die E-Mail-Adresse einem Kunden zu oder überprüfe, ob mehrere Kunden im System vorhanden sind, die einen Kunden repräsentieren und füge diese zu einem Kunden zusammen."
    },
    "zammadCustomer": "Zammad-Kunde",
    "zammadFullCommunication": "Zammad volle Kundenkommunikation",
    "hideClosedTickets": "Geschlossene Tickets ausblenden",
    "zammadTicketNr": "Zammad Ticketnummer"
  },
  "_ticketEditPanelItemTitle": {
    "testing": "Tests",
    "orderResidual": "Rückkauf Auftrag",
    "upgrade": "Upgrade",
    "tasks": "Aufgaben",
    "protocol": "Protokoll",
    "magentoOrder": "Magento-Bestellung",
    "parentTickets": "Super-Tickets",
    "childTickets": "Sub-Tickets",
    "relatedCustomer": "Zugehörige Kunden",
    "addresses": "Adressen",
    "relatedProductInstances": "Zugehörige Produktinstanz",
    "relatedProducts": "Zugehörige Produkte",
    "productInstanceToRepair": "Produktinstanz zum Reparieren",
    "productToRepair": "Produkt zum Reparieren",
    "repairParts": "Reparaturteile",
    "attachments": "Anlagen",
  },
  "_tasks": {
    "addtask": "Aufgabe hinzufügen",
    "itemDescription": "Aufgaben Beschreibung",
  },
  "_protocol": {
    "text": "Protokolltext",
    "addProtocolItem": "Füge einen neuen Protokollbeitrag hinzu",
    "edit": "Protokollbeitrag bearbeiten",
  },
  "_upgradeOffer": {
    "titleOldDevice": "Altes Gerät",
    "titleUpgradeOffer": "Upgrade Angebot",
    "titleUpgradeProduct": "Zusätzliche Produkte",
    "addDeviceToUpgrade": "Geräte zum Upgrade hinzufügen",
    "oldMagentoOrder": "Magento-Bestellung",
    "noMagentoOrder": "Keine Magento-Bestellung",
    "product": "Produkt",
    "payedPrice": "Bezahlter Preis",
    "shipment": "Versand",
    "deviceAgeInMonth": "Alter der Geräte in Monaten",
    "acceptedValue": "Anrechnungswert",
    "description": "Beschreibung",
    "addOldDeviceToUpgrade": "Alte Geräte hinzufügen zum Upgrade",
    "addNewDevice": "Neues Gerät hinzufügen",
    "upgradeDifferencePrice": "Upgrade-Differenzpreis",
    "devicePrice": "Gerätepreis",
    "upgradePrice": "Upgradepreis",
    "totalPrice": "Gesamtpreis",
    "offerAccepted": "Angebot angenommen",
    "magentoOrderNumber": "Magento-Bestellnummer",
    "advanceDeviceDeposit": "Vorgerät Pfand",
    "pledgePrice": "Pfandpreis",
    "magentoOrderNumberDeposit": "Magento-Bestellnummer Pfand",
    "remove": "Entfernen",
    "type": "Typ",
    "oldDevice": "Altes Gerät",
    "newDevice": "Neues Gerät",
  },
  "_magentoOrder": {
    "titleOrders": "Bestellte Artikel",
    "titleOrderComments": "Kommentare",
    "titleAddresses": "Adressen",
    "openOrderInMagento": "Öffne die Magento-Bestellung im Browser",
    "subtotal": "Gesamtbetrag",
    "base_tax_amount": "Basissteuerbetrag",
    "base_grand_total": "Gesammtbetrag inkl. Steuern",
    "shipping_description": "Versandbeschreibung",
    "shipping_incl_tax": "Versand inkl. Steuern",
    "payment_method": "Zahlungsmethode",
    "tax_name": "Steuername",
    "tax_rate": "Steuersatz",
    "_orderItem": {
      "qty_ordered": "Bestellmenge",
      "base_price": "Basispreis",
      "base_price_incl_tax": "Basispreis inkl. Steuern",

    },
    "_order_comments": {
      "status": "Kommentarstatus",
      "comment": "Kommentar",
      "created_at": "Erstellungsdatum"
    },
    "state": {
      "processing": "Verarbeitung",
      "processing_preorder": "Verarbeitung (Vorbestellung)",
      "processing_preorder_prio": "Verarbeitung (Vorbestellung mit Priorität)",
      "processing_preorder_ready": "Verarbeitung (Vorbestellung ist bereit)",
      "processing_rent": "Verarbeitung (Rental)",
      "pending_payment": "Ausstehende Zahlung",
      "fraud": "Fraud",
      "payment_review": "Zahlungsbewertung",
      "pending": "Ausstehend",
      "holded": "Gesperrt",
      "manuell_preorder": "Manuell (Vorbestellung)",
      "manuell_preorder_prio": "Manuell (Vorbestellung mit Priorität)",
      "manuell_preorder_ready": "Manuell (Vorbestellung ist bereit)",
      "manuell_rent": "Manuell (Rental)",
      "manuell": "Manuell",
      "complete": "Abgeschlossen",
      "manuell_on_hold": "Manuell (in Warteschlange)",
      "closed": "Geschlossen",
      "canceled": "Storniert",
      "pending_paypal": "Ausstehende PayPal",
      "paypal_reversed": "PayPal Rückbuchung",
      "paypal_canceled_reversal": "PayPal Stornierung",
      "undefined": ""
    }
  },
  "_workflow": {
    "_name": {
      "Upgrade Order Deposit included": "Upgrade Bestellung Pfand eingeschlossen",
      "Upgrade Order Deposit PostPayed": "Upgrade Bestellung Pfand Nachbestellt",
      "Upgrade Order return first": "Upgrade Bestellung ohne Pfand",
      "Kombiangebot without deposit": "Kombiangebot ohne Pfand",
      "Kombiangebot with deposit": "Kombiangebot mit Pfand",
      "loan_device_workflow_order_no_deposit": "Leihgerät-Workflow Bestellung ohne Pfand",
      "loan_device_workflow_order_with_deposit": "Leihgerät-Workflow Bestellung mit Pfand",
      "combiOfferWithDeposit": "Kombiangebot mit Pfand",
      "combiOffer": "Kombiangebot ohne Pfand",
    },
  },
  "_templateBuilder": {
    "prevStep1": "Zurück",
    "prevStep2": "Zurück zur Bearbeitung",
    "prevStep3": "Zurück zur Vorschau",
    "nextStep1": "Vorschau anzeigen",
    "nextStep2": "PDF anzeigen",
    "nextStep3": "Weiter",
    "titleEdit": "Template-Bearbeiten",
    "titlePreview": "Template-Vorschau",
    "titleGenerate": "PDF-Vorschau",
    "alertCustomerNotFound": "Kunde wurde nicht gefunden.",
    "alertCustomerContactsNotFound": "Kunden Kontakte wurden nicht gefunden.",
    "alertTicketIsRequired": "Zur Erzeugung der Template-Vorschau muss eine Ticket-ID vergeben werden.",
    "alertPreviewIsRequired": "Lade zuerst die Template-Vorschau, um das PDF anhängen zu können.",
    "pleaseAddBillingAddress": "Bitte eine Rechnungsadresse angeben.",
    "create": "Template anlegen",
    "add": "PDF anhängen",
    "loadingText": "Lade...",
    "loadingDescription": "PDF wird erstellt und dem Ticket angehängt. Bitte warten einen Moment.",
    "filterByProductInstanceName": "Nach Produktinstanz filtern",
    "oldProductInstances": "Alte Produktinstanzen",
    "newProductInstances": "Neue Produktinstanzen",
    "updatedAt": "Zuletzt aktualisiert am",
    "showProductInstanceSelector": "Produktinstanz-Auswahl anzeigen",
  },
  "_formReturn": {
    "title": "Rückgabe Formular",
    "selectFormLabel": "Formular",
    "submitFormBtn": "Formular absenden",
    "pleaseSelectInfo": "Um alles in die richtige Bahn zu lenken, müssen wir wissen, was der Grund für die Rückgabe ist. Bitte wähle zuerst deinen Rückgabegrund aus. Wenn du dir nicht sicher bist, ob es sich um einen Widerruf handelt, kannst du hier #https://shop.shiftphones.com/widerruf/# nachlesen.",
    "repair": {
      "title": "Reparaturanfrage",
      "info": "Du hast ein defektes Gerät und möchtest es reparieren lassen."
    },
    "update": {
      "title": "Upgradeanfrage",
      "info": "Du möchtest dein altes Gerät gegen ein neues eintauschen. Dabei wird der Restwert deines alten Gerätes ermittelt und mit dem Kaufpreis des neuen Gerätes verrechnet.",
    },
    "return": {
      "title": "Widerrufanfrage",
      "info": "Du möchtest von Deinem Widerrufsrecht Gebrauch machen und das Gerät an uns zurücksenden - siehe",
      "reason": {
        "Cancellation, revocation": "Stornierung oder Widerruf",
        "Item does not meet expectations/requirements": "Artikel entspricht nicht meinen Erwartungen oder Anforderungen",
        "I do not like the look of it": "Das Design gefällt mir nicht",
        "do not like it too heavy": "Der Artikel ist mir zu schwer",
        "do not like hardware/software": "Die Hardware oder Software sagt mir nicht zu",
        "do not like item quality poor": "Die Qualität des Artikels überzeugt mich nicht",
        "Camera bad": "Die Kameraqualität ist nicht zufriedenstellend",
        "Voice quality poor": "Die Sprachqualität ist unzureichend",
        "Battery performance poor": "Die Akkulaufzeit ist zu kurz",
        "Feel bad": "Die Haptik ist nicht angenehm",
        "defective hardware": "Die Hardware ist defekt",
        "defective software, Android old, apps not working": "Die Software ist fehlerhaft oder veraltet, Apps funktionieren nicht",
        "Wrong item ordered (customer)": "Ich habe den falschen Artikel bestellt",
        "incorrect item delivered (SHIFT)": "Ich habe einen falschen Artikel erhalten",
        "Customer wants to exchange items": "Ich möchte den Artikel umtauschen",
        "Delivery with defect": "Der gelieferte Artikel ist beschädigt oder defekt"
      }
    },
    "revocation": {
      "title": "Einsendungsantrag",
      "info": "Du möchtest dein altes Gerät zurückgeben und den Restwert erstattet bekommen oder du möchtest, dass das Gerät dem Recycling zugeführt wird."
    },
    "reason": {
      "title": "Rückgabegrund",
      "label": "Grund",
    },
    "contact": {
      "billingAddressTitle": "Rechnungsanschrift",
      "shippingAddressTitle": "Versandanschrift",
      "info": "",
      "selfPickup": "Selbstabholung",
      "sameAsBillingAddress": "Rechnungsadresse gleich der Lieferadresse",
      "infoSwitzerland": "Wenn du aus der Schweiz kommst, wende dich bitte an unsere Freunde von",
      "switzerlandLinkText": "aldernativ.ch",
      "switzerlandLink": "https://aldernativ.ch",
      "infoNotEuLand": "Sendungen aus Nicht-EU-Ländern können nicht angenommen werden.",
      "openInANewTab": "Öffne in einem neuen Tab"
    },
    "order": {
      "title": "Angaben zur Bestellung",
      "info": "Wenn du deine Bestellnummer verlegt hast, gib stattdessen das ungefähre Datum deiner Bestellung an. Dies hilft uns, deinen Fall schneller zu finden und zu bearbeiten.",
      "id": "Bestellnummer",
      "date": "Bestelldatum",
    },
    "device": {
      "title": "Angaben zum Gerät",
      "designation": "Gerätbezeichnung",
      "imei1": "IMEI 1",
      "imei2": "IMEI 2",
      "sn": "Seriennummer",
      "description": "Beschreibung des Geräts",
      "errorDescription": "Fehlerbeschreibung.",
      "info": "Die International Mobile Equipment Identity (IMEI) ist eine Nummer, die jedes Handy und Smartphone eindeutig identifiziert. Sie besteht aus 15 Ziffern und befindet sich im Akkufach. SHIFTbooks haben eine 15-stellige Seriennummer (SN), die sich neben dem Akkufach befindet.",
    },
    "credentials": {
      "title": "Zugangsdaten",
      "pin": "PIN",
      "muster": "Muster",
      "info": "Wir möchten dich darauf hinweisen, dass wir keine Garantie für die Erhaltung der Daten auf dem Gerät übernehmen können. Unter der Voraussetzung, dass wir Zugriff auf das Gerät haben, besteht eine größere Wahrscheinlichkeit, dass die Daten erhalten bleiben. Wird kein Sperrcode angegeben und das Gerät ist gesperrt, flashen wir das Gerät, da wir es sonst nicht testen können. Deine Daten gehen dabei auf jeden Fall verloren."
    },
    "deposit": {
      "title": "Gerätepfand",
      "consultation": "wird nach Rücksprache festgelegt",
      "coupon": "als Shop-Gutschein",
      "donate": "als Spende",
      "transfer": "als Überweisung",
      "bankDetails": "Bankverbindungsdaten",
      "accountOwner": "Konto-InhaberIn",
      "iban": "IBAN",
      "bic": "BIC",
    },
    "attachment": {
      "title": "Anlage hinzufügen"
    },
    "terms": {
      "text": "Mit der Einsendung bestätige ich, dass ich die Einsende- und Reparaturhinweise unter #https://shift.eco/reparatur# gelesen und akzeptiert habe.",
      "linkText": "shift.eco/reparatur",
      "link": "https://shift.eco/reparatur",
    },
    "submit": {
      "yourTicketNumber": "Dein Ticketnummer",
      "yourTicketNumberInfo": "Du kannst dir deine Ticketnummer notieren. Bei Rückfragen hilft es uns, deine Anfrage schneller zu finden.",
      "errorInfo": "Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal ooder senden Sie uns eine E-Mail.",
      "okBtn": "Bestätigen"
    }
  },
  "_dialogReturn": {
    "header": {
      "formType": "Grund für die Rückgabe",
      "address": "Adresse angeben",
      "credentials": "Zugangsdaten eingeben",
      "deposit": "Gerätepfand Verwendung",
      "orderInfo": "Bestellinformationen eingeben",
      "finished": "Abschluss des Antrags",
      "deviceInfo": "Geräte-Identifikation und Fehlerbeschreibung",
      "confirmation": "Bestätigung"
    },
    "orderInfo": {
      "title": "Gerät-Bestellangaben",
      "labelOrderId": "Bestellnummer",
      "labelOrderDate": "Bestelldatum",
      "info": "Bitte Bestellnummer angeben. Dies hilft uns, deinen Fall schneller zu finden und zu bearbeiten. Wenn du deine Bestellnummer verlegt hast, gib bitte das ungefähre Datum deiner Bestellung an.",
      "errorOrderId": "Die Bestellnummer ist ungültig.",

    },
    "formType": {
      "title": "Rückgabengrund",
      "repair": "Reparatur",
      "upgrade": "Upgrade",
      "return": "Rückgabe",
      "revocation": "Widerruf",
      "labelReturnReason": "Grund des Widerrufs",
      "infoRepair": "Du hast ein defektes Gerät und möchtest es reparieren lassen.",
      "infoUpgrade": "Du möchtest dein altes Gerät gegen ein neues eintauschen. Dabei wird der Restwert deines alten Gerätes ermittelt und mit dem Kaufpreis des neuen Gerätes verrechnet.",
      "infoReturn": "Du möchtest dein altes Gerät zurückgeben und den Restwert erstattet bekommen oder du möchtest, dass das Gerät dem Recycling zugeführt wird..",
      "infoRevocation": "Du möchtest von deinem Widerrufsrecht Gebrauch machen und das Gerät zurückgeben.",
    },
    "address": {
      "billing": "Rechnungsanschrift",
      "shipping": "Versandanschrift",
      "self_pickup": "Selbstabholung",
      "same_as_billing": "Ist identisch mit der Rechnungsanschrift",
      "noValidBillingAddress": "Rechnungsanschrift bitte vollständig ausfüllen.",
      "noValidShippingAddress": "Versandanschrift bitte vollständig ausfüllen.",
      "errorMessage": "Bitte füllen Sie alle mit * gekennzeichneten Felder korrekt aus.",
      "errorCountry": "Es wird nur deutschlandweit versendet.",
    },
    "pin": {
      "title": "PIN-Code oder Muster eingeben",
      "label": "PIN-Code eingeben",
      "warningFourValues": "Gib eine PIN oder ein Muster mit mindestens vier Werten an.",
      "info": "Wir möchten dich darauf hinweisen, dass wir keine Garantie für die Erhaltung der Daten auf dem Gerät übernehmen können. Unter der Voraussetzung, dass wir Zugriff auf das Gerät haben, besteht eine größere Wahrscheinlichkeit, dass die Daten erhalten bleiben."
    },
    "deviceInfo": {
      "titleDesignation": "Name des Gerätes",
      "titleImei": "Mobile Geräte-Identifikation",
      "titleSerialnumber": "Desktop-Geräte-Identifikation",
      "designation": "Gerätbezeichnung",
      "designationPlaceholder": "SHIFTbook, SHIFT6mq, SHIFTsound BNO ...",
      "imei1": "IMEI 1",
      "imei2": "IMEI 2",
      "sn": "Seriennummer (SN)",
      "infoImei": "Die International Mobile Equipment Identity (IMEI) ist eine Nummer, die jedes Mobiltelefon und Smartphone eindeutig identifiziert. Sie besteht aus 15 Stellen und befindet sich im Akkufach.",
      "infoSerialnumber": "SHIFTbooks besitzen eine Seriennummer (SN). Sie besteht aus 15 Stellen und befindet sich neben dem Akkufach.",
      "titleErrorDescription": "Fehlerbeschreibung"
    },
    "deposit": {
      "titleDeviceDeposit": "Gerätepfand",
      "consultation": "wird nach Rücksprache festgelegt",
      "coupon": "als Shop-Gutschein",
      "donate": "als Spende",
      "transfer": "als Überweisung",
      "titleBankDetails": "Bankverbindungsdaten",
      "accountOwner": "Konto-InhaberIn",
      "iban": "IBAN",
      "bic": "BIC",
      "error": "Die Angaben zum Konto sind nicht korrekt oder nicht vollständig ausgefüllt."
    },
    "confirmation": {
      "repair": "Reparatur-Formular",
      "upgrade": "Upgrade-Formular",
      "return": "Widerruf-Formular",
      "revocation": "Einsendungsantrag",
      "errorForm": "Es ist ein Fehler aufgetreten.",
      "self_pickup": {
        "yes": "Ich möchte das Gerät selbst abholen",
        "no": "Ich möchte die Zusendung des Gerätes per Post erhalten"
      },
      "attachments": {
        "label": "Anlage hinzufügen"
      },
      "subheader": {
        "receive": "Art der Zustellung",
        "contactInfo": "Kontaktinformationen",
        "orderInfo": "Bestellinformationen",
        "deviceAccessInfo": "Gerätezugriffsinformationen",
        "deposit": "Pfandvergütung",
        "errorDescription": "Angaben über das abzugebende Gerät",
        "attachments": "Bilder oder anderes Material dem Formular hinzufügen"
      },
      "infoSwitzerland": "Wenn du aus der Schweiz kommst, wende dich bitte an unsere Freunde von",
      "switzerlandLinkText": "aldernativ.ch",
      "switzerlandLink": "https://aldernativ.ch",
      "infoNotEuLand": "Sendungen aus Nicht-EU-Ländern können nicht angenommen werden.",
      "confirmationText": "Mit der Einsendung bestätige ich, dass ich die Einsende- und Reparaturhinweise unter #https://shift.eco/reparatur# gelesen und akzeptiert habe.",
      "repairLinkText": "shift.eco/reparatur",
      "repairLink": "https://shift.eco/reparatur",
      "submitForm": "Formular abschicken",
      "formHasBeenSubmitted": "Formular wurde gesendet",
      "infoReloadPage": "Die Seite wird in 10 Sekunden neu geladen."
    },
    "requestCompleted": {
      "title": "Ihre Anfrage wurde gesendet",
      "titleError": "Fehler bei der Übertragung",
      "info": "Ihr Ticket ist erstellt und wird bearbeitet. Bitte notieren Sie sich Ihre Ticketnummer und legen Sie sie Ihrer Sendung bei.",
      "infoError": "Beim Absenden der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "infoFileError": "Fehler bei der Übertragung der angehängten Dateien",
      "label": "Ticket-Nummer",
      "linkRefresh": "Startseite",
      "linkSendAgain": "Erneut senden",
      "ticketNumber": "Ticketnummer",
      "text": "Bitte notieren Sie sich Ihre Ticketnummer. Dies erleichtert uns bei Rückfragen das Auffinden Ihres Falles.",
      "sendAgain": "Formular erneut senden",
      "finish": "Zurück zur Startseite"
    }
  },
  ":": ":",
  "The ticket needs one of the following Parameter": "Dieses Ticket benötigt eines der folgenden Parameter",
  "Support Tickets": "Support Tickets",
  "IT Tickets": "IT Tickets",
  "_confirmationText": {
    "removeProductAmount": "Sind Sie sicher, dass Sie die Produktmenge entfernen möchten?",
    "removeParentTicketFromTicket": "Sind Sie sicher, dass Sie das übergeordnete Ticket von diesem Ticket entfernen möchten?",
    "removeChildTicketFromTicket": "Sind Sie sicher, dass Sie das untergeordnete Ticket von diesem Ticket entfernen möchten?",
    "removeAttachmentFromTicket": "Sind Sie sicher, dass Sie diesen Anhang von diesem Ticket entfernen möchten?",
    "removeAddressFromTicket": "Sind Sie sicher, dass Sie diese Adresse aus diesem Ticket entfernen möchten?",
    "reopenTicket": "Sind Sie sicher, dass Sie das Ticket erneut öffnen wollen?",
    "removeTicketFromProductInstance": "Sind Sie sicher, dass Sie dieses Ticket aus dieser Produktinstanz entfernen möchten?",
    "removeProductInstanceFromTicket": "Bist du sicher, dass du diese Produktinstanz von diesem Ticket entfernen möchten?",
    "removeTicketFromCustomer": "Sind Sie sicher, dass Sie dieses Ticket von diesem Kunden entfernen möchten?",
    "removeAddressFromCustomer": "Sind Sie sicher, dass Sie diese Adresse von diesem Kunden entfernen möchten?",
    "deleteTicket": "Sind Sie sicher, dass Sie dieses Ticket löschen möchten?",
    "removeCustomerFromTicket": "Sind Sie sicher, dass Sie diesen Kunden von diesem Ticket entfernen möchten?",
    "removeCustomerFromOrganization": "Sind Sie sicher, dass Sie diesen Kunden aus dieser Organisation entfernen möchten?"
  },
  "_footerProperty": {
    "createdBy": "Erstellt von {user} am {date}",
    "updatedBy": "Zuletzt geändert von {user} am {date}",
    "created": "Erstellt am {date}",
    "updated": "Zuletzt geändert am {date}",
    "noObject": "Keine Ticket Id gefunden"
  },
  "_theme": {
    "title": "Theme",
    "description": "Passen Sie das Aussehen Ihrer Anwendung mit hellen und dunklen Themen an.",
    "light": "Helles Theme",
    "dark": "Dunkeles Theme",
    "system": "Systemeinstellung"
  },
  "_language": {
    "title": "Sprache",
    "description": "Stellen Sie die Sprache Ihrer Anwendung ein. Einige Übersetzungen werden erst nach einem Neuladen der Seite übernommen.",
    "de": "Deutsch",
    "en": "English"
  },
  "_globalSort": {
    "title": "Listen global Sortieren",
    "description": "Globale Liste Sortierrichtung nach Alter festlegen.",
    "oldestFirst": "Aufsteigend (älteste zuerst)",
    "oldestLast": "Absteigend (älteste zuletzt)"
  },
  "_trackingCodeType": {
    "DHL": "DHL",
    "POST": "POST"
  },
  "_ticket": {
    "tags": "Tags",
    "_info": {
      "close_date": "Schließdate",
    },
    "_task": {
      "addAccessoriesTask": "Eingesendetes Zubehör hinzufügen",
    },
    "shipment_return_required": "Rückgabe erforderlich",
    "id": "Backend ID",
    "type": "Typ",
    "refund": "Rückerstattung",
    "payment_id": "IBAN / Paypal",
    "shipment_type": "Zustellungart",
    "preorder_group": "Vorbestellungs Gruppe",
    "preorder_prio_group": "Vorbestellung Prioritätgruppe",
    "preorder_state": "Vorbestellung Status",
    "invoice_number": "Rechnungsnummer",
    "preorder_original_position": "Vorbestellung Position Orig",
    "_preorder_group": {
      'SP8': "SHIFTphone8"
    },
    "_preorder_prio_group": {
      "normal": "normal",
      "Prio1": "Prio 1",
      "Prio2": "Prio 2",
      "Prio3": "Prio 3",
      "Prio4": "Prio 4",
      "Prio5": "Prio 5",
      "Wait": "Wartet",
      "WaitEndOf2024": "Wartet bis Ende 2024",
      "WaitBeginOf2025": "Wartet bis Beginn 2025",
      "WaitOSCertificate": "Wartet auf OSCertifikat",
      "WaitLiteOS": "Wartet auf LiteOS",
      "WaitForCamera": "Wartet auf Kamera",
      "WaitForOfficialRelease": "Wartet auf offizielle Release",
      "WaitFeedbackSP8GSM": "Wartet auf Feedback SP8 GSM",
      "WaitForSP8_1": "Wartet auf SP8.1",
      "CanBeSold2ToBusiness": "Kann an B2B verkauft werden",
      "SP8_B_Ware": "SP8.0 B Ware",
      "SP8_share": "SP8 share",
    },
    "_protocols": {
      "text": "Protokolltext",
    },
    "preorder_position": "Vorbestellung Position",
    "preorder_order_date": "Vorbestellungsdatum",
    "preorder_estimation": "Vorbestellungs Schätzdatum",
    "_shipment_type": {
      "shipment": "Versand",
      "self_collection": "Selbstabholung"
    },
    "_team": {
      "name": "Team",
    },
    "_watchers": {
      "username": "Beobachter"
    },
    "_product_instances": {
      "_product": {
        "unique_name": "Produktinstanz Name"
      }
    },
    "customer": "Kunde",
    "magento_order_payment_method": "Zahlungsmethode",
    "ticket_id": "Ticket Nummer",
    "magento_order_state": "Magento Status",
    "createdAt": "Erstellungsdatum",
    "updatedAt": "Änderungsdatum",
    "advance_device_deposit": "Pfand für Vorabgerät",
    "name": "Name",
    "description": "Beschreibung",
    "magento_order_number_preorder": "Magento-Bestellung Vorbestellung",
    "magento_order_number_deposit": "Magento-Bestellung Pfand",
    "magento_order_number_payment": "Magento-Bestellung Zahlung",
    "magento_order_number_newOrder": "Neue Magento-Bestellung",
    "magento_order_number_previousOrder": "Vorherige Magento-Bestellung",
    "diverenceAmount": "Differenzbetrag",
    "package_number": "Paketnummer (intern)",
    "shipment_number": "Sendungsnummer",
    "tracking_code": "Tracking-Code",
    "tracking_code_type": "Tracking-Code Typ",
    "owner": "Eigentümer",
    "checked": "Überprüft",
    "_owner": {
      "username": "@:_ticket.owner"
    },
    "team": "Team",
    "watchers": "Beobachter",
    "store": "Lager",
    "state": "Status",
    "priority": "Priorität",
    "_priority": {
      "Medium": "Normal",
      "High": "Hoch",
      "Low": "Niedrig"
    },
    "sub_type": "Untertyp",
    "subType": "Untertyp",
    "_sub_type_tooltip": {
      'privateSale': 'Privat verkauf von Kunde zu Kunde zu Dokumentation',
      'production': 'Versand von Produktionen zu SHIFT',
      'stolen': 'Nur für gestohlene Geräte',
      'loan device': 'Für Leihgeräte zu einer Preorder',
      'residualValueForecast': 'Für vorläufige Restwerte per E-Mail',
      'cancelled order': 'für Widerrufe innerhalb 14 Tage nach Lieferung',
      'refund': 'Für sonst. Erstattungen, die kein Widerruf sind',
      'shipmentInvestigationOrder': 'für eingereichte Nachforschungsaufträge',
      'upgradeProduct': 'Produkt mit einem Upgrade includiert',
      'customerPartialDelivery': 'Für Teillieferungen mit Porto-Nachzahlung',
      'wrongDelivery': 'Für falsche oder unvollständige Lieferungen',
      'repurchase': 'Rücksendung für Restwert',
    },
    "_sub_type": {
      "undefined": "Nicht vergeben",
      "refund": "Rückerstattung",
      "Neubestellung": "Neubestellung",
      "loan device": "Leihgerät",
      "upgrade": "Upgrade",
      "residualValueForecast": "Restwertprognose",
      "question": "Anfrage",
      "stolen": "Gestohlen",
      "other": "Sonstiges",
      "exchangeDevice": "Tauschgerät",
      "with costs": "Mit Kosten",
      "guarantee": "Garantie",
      "SL-refurbishment": "SL-Wiederaufarbeitung",
      "goodwill": "Kulanz",
      "unknown": "Unbekannt",
      "customer": "Kunde",
      "production": "Produktion",
      "defect": "Defekt",
      "outdated": "Veraltet",
      "privateSale": "Privatverkauf",
      "wrongDelivery": "Falschlieferung",
      "repurchase": "Rückkauf",
      "cancelled order": "Widerruf",
      "loaner return": "Rückgabe Leihgerät",
      "repair": "Reparatur",
      "undeliverable": "Nicht verfügbar",
      "cancellation": "Widerruf",
      "rebooking": "Umbuchung",
      "recycle": "Recycling",
      "partialDelivery": "Teillieferung",
      "customerPartialDelivery": "Kunden Teillieferung",
      "shipmentInvestigationOrder": "Versand-Nachforschung",
      "null": "",
      "upgradeProduct": "Kombiangebot",
    },
    "_state": {
      "open": "neu",
      "stolen": "Gestohlen",
      "WaitForGoods": "Warte auf Rückgabe",
      "waitForPreorder": "Warte auf Vorbestellung",
      "inProgress": "In Bearbeitung",
      "closed": "Geschlossen",
      "inProduction": "In Produktion",
      "inOrder": "In Bestellung",
      "preOrder": "Vorbestellung",
      "waitForCustomer": "Warte auf den Kunden",
      "canceled": "Abgebrochen",
      "payed": "Bezahlt",
      "waitForPayment": "Warte auf Zahlung",
      "waitForDevice": "Warte auf Gerät",
      "waitForKVA": "Warte auf KVA",
      "waitingForKVA": "Warte auf KVA",
      "waitForRepair": "Warte auf Reparatur",
      "inRepair": "In Reparatur",
      "readyForShipment": "Bereit für Versand",
      "waitForOrderCreation": "Warte auf Auftragserstellung",
      "waitForCustomerInformation": "Warte auf Infos vom Kunden",
      "undefined": "Status nicht bekannt",
      "demand": "Nachfrage",
      "inquiry": "Rückfrage",
      "ready": "Bereit",
      "waitForRefund": "Warte auf Rückerstattung",
      "waitForPaymentDeposit": "Warte auf Pfand Zahlung",
      "waitForPayment2": "Warte auf 2. Zahlung",
      "payedDeposit": "Pfand bezahlt",
      "repaired": "Repariert",
      "waitForShipment": "Warte auf Lieferung",
      "waitForChecking": "Warte auf Kontrolle",
      "WaitForInvestigation": "Warte auf Nachforschung",
      "InvestigationFinished": "Nachforschung beendet",
      "creatingKVA": "KVA wird erstellt",
      "waitForCustomer2": "Warte auf den Kunden",
      "readyForShipment2": "Bereit für Versand",
      "waitForGoods2": "Warte auf Rückgabe",
      "readyForShipment3": "Bereit für Versand",
      "waitForRefund2": "Warte auf Rückerstattung",
      "ticketCleanup": "Ticket Bereinigung",
      "null": "Kein Status",
    },
    "_type": {
      "support": "Support",
      "repair": "Reparatur",
      "book_in": "Einbuchen",
      "book_out": "Ausbuchen",
      "production_order": "Produktions Auftrag",
      "product_order": "Produkt Auftrag",
      "testing": "Test",
      "delivery": "Lieferung",
      "shipment": "Versand",
      "quality_assurance": "Qualitätssicherung",
      "hardware_definition": "Hardware-Definition",
      "special": "Spezial",
      "return": "Rückgabe",
      "recycle": "Recycling",
      "software_update": "Software-Update",
      "hardware_preparation": "Hardware Vorbereitung"
    },
    "custom_data": {
      "repair_diagnosis": "Diagnose",
      "return_reason": "Rückgabegrund",
      "_return_reason": {
        "Cancellation, revocation": "Stornierung, Widerruf",
        "Item does not meet expectations/requirements": "Artikel entspricht nicht den Erwartungen/Anforderungen",
        "I do not like the look of it": "Optik gefällt mir nicht",
        "do not like it too heavy": "zu schwer gefällt mir nicht",
        "do not like hardware/software": "Hardware/Software gefällt mir nicht",
        "do not like item quality poor": "Artikelqualität schlecht gefällt mir nicht",
        "Camera bad": "Kamera schlecht",
        "Voice quality poor": "Sprachqualität schlecht",
        "Battery performance poor": "Akkuleistung schlecht",
        "Feel bad": "Feinfühlung",
        "defective hardware": "defekte Hardware",
        "defective software, Android old, apps not working": "defekte Software, Android alt, Apps funktionieren nicht",
        "Wrong item ordered (customer)": "Falscher Artikel bestellt (Kunde)",
        "incorrect item delivered (SHIFT)": "falscher Artikel geliefert (SHIFT)",
        "Upgrade to better SHIFTPHONE": "Upgrade auf besseres SHIFTPHONE",
        "Customer wants to exchange items": "Kunde möchte Artikel umtauschen",
        "Undeliverable, returned due to incorrect shipping address": "Unzustellbar, Retoure wegen falscher Lieferadresse",
        "Customer did not pick up item at post office": "Kunde hat Artikel nicht bei der Post abgeholt",
        "Does not correspond to the shipping conditions, package does not fit in the mailbox of the packing station": "Entspricht nicht den Versandbedingungen, Paket passt nicht in den Briefkasten der Packstation",
        "Delivery with defect": "Lieferung mit Defekt"
      },
      "testing": { state: "Testeing Status" },
      "todo": "Aufgabe",
      "residualOrder": {
        "productId": "Rückkauf Produkt",
        "originalPrice": "Bezahlter Preis ",
        "age": "Gerätealter",
        "surrenderValue": "Rückkaufwert",
        "description": "Beschreibung"
      },

    },
    "product_instances": "Produktinstanzen",
    "product": "Produkt",
    "due_date": "Fälligkeitsdatum",
    "magento_order_number": "Magento Bestellnummer",
    "_workflow": {
      "loan_device_sold": "Kunde kauft Leihgerät"
    }
  },
  "_team": {
    "Repair": "Reparatur",
    "Support": "Support",
    "Dispatch": "Versand",
    "IT": "IT",
    "Finance": "Finanzen",
    "Return": "Rückgabe",
    "Receipt": "Annahme",
    "Production": "Produktion",
    "": ""
  },
  "_notification": {
    "title": "Benachrichtigungen",
    "description": "Wenn du zu viele Benachrichtigungen bekommst, kannst du sie hier alle auf einmal löschen. Die Benachrichtigungen werden erst nach einem Neuladen der Seite übernommen.",
    "deleteAll": "Alle Benachrichtigungen löschen",
  },
  "_notification_item": {
    "sub_type": "Untertyp",
    "priority": "Priorität",
    "state": "Status",
    "name": "Name",
    "owner": "Eigentümer",
    "description": "Beschreibung",
    "changed": "wurde geändert",
    "team": "Weiterleitung des Tickets an das Team",
    "from": "von",
    "to": "auf",
    "watchers": "Neue(r) Beobachter wurde(n) hinzugefüght",
    "new Protocol Entity": "Ein neuer Eintrag in das Protokoll wurde hinzugefügt",
    "configNeeded": "Zusätzliche Benachrichtigungskonfiguration erforderlich",
    "last_update_user": "Letzte Aktualisierung durch Benutzer",
    "removed": "Entferntes",
    "child_tickets": "Unterticket",
    "tracking_code_type": "Tracking Code Typ",
    "tracking_code": "Tracking Code",
    "preorder_prio_group": "Preorder Prioritätgruppe",
    "magento_order_number": "Magento Bestellnummer",
    "Product Instances": "Produktinstanzen",
    "tags": "Tags",
  },
  "_addressType": {
    "billing": "Rechnungsadresse",
    "shipping": "Lieferadresse"
  },
  "_address": {
    "type": "Typ",
    "_type": {
      "billing": "Rechnungsanschrift",
      "shipping": "Versandanschrift"
    },
    "company": "Firma",
    "firstname": "Vorname",
    "lastname": "Nachname",
    "name": "Name",
    "email": "E-Mail",
    "phone": "Telefonnummer",
    "street": "Straße",
    "postcode": "PLZ",
    "city": "Stadt",
    "country_id": "Land",
    "country": "Land",
    "_country_id": {
      "undefined": "Nicht vergeben",
      "Afghanistan": "Afghanistan",
      "Aland Islands": "Ålandinseln",
      "Albania": "Albanien",
      "Algeria": "Algerien",
      "American Samoa": "Amerikanischamoa",
      "Andorra": "Andorra",
      "Angola": "Angola",
      "Anguilla": "Anguilla",
      "Antarctica": "Antarktis",
      "Antigua And Barbuda": "Antigua und Barbuda",
      "Argentina": "Argentinien",
      "Armenia": "Armenien",
      "Aruba": "Aruba",
      "Australia": "Australien",
      "Austria": "Österreich",
      "Azerbaijan": "Aserbaidschan",
      "Bahamas": "Bahamas",
      "Bahrain": "Bahrain",
      "Bangladesh": "Bangladesch",
      "Barbados": "Barbados",
      "Belarus": "Belarus",
      "Belgium": "Belgien",
      "Belize": "Belize",
      "Benin": "Benin",
      "Bermuda": "Bermuda",
      "Bhutan": "Bhutan",
      "Bolivia": "Bolivien",
      "Bosnia And Herzegovina": "Bosnien und Herzegowina",
      "Botswana": "Botswana",
      "Bouvet Island": "Bouvetinsel",
      "Brazil": "Brasilien",
      "British Indian Ocean Territory": "Britisches Territorium im Indischen Ozean",
      "Brunei Darussalam": "Brunei Darussalam",
      "Bulgaria": "Bulgarien",
      "Burkina Faso": "Burkina Faso",
      "Burundi": "Burundi",
      "Cambodia": "Kambodscha",
      "Cameroon": "Kamerun",
      "Canada": "Kanada",
      "Cape Verde": "Kap Verde",
      "Cayman Islands": "Kaimaninseln",
      "Central African Republic": "Zentralafrikanische Republik",
      "Chad": "Tschad",
      "Chile": "Chile",
      "China": "China",
      "Christmas Island": "Weihnachtsinsel",
      "Cocos (Keeling) Islands": "Kokosinseln (Keelinginseln)",
      "Colombia": "Kolumbien",
      "Comoros": "Komoren",
      "Congo": "Kongo",
      "Congo, Democratic Republic": "Demokratische Republik Kongo",
      "Cook Islands": "Cookinseln",
      "Costa Rica": "Costa Rica",
      "Cote D\"Ivoire": "Elfenbeinküste",
      "Croatia": "Kroatien",
      "Cuba": "Kuba",
      "Cyprus": "Zypern",
      "Czech Republic": "Tschechische Republik",
      "Denmark": "Dänemark",
      "Djibouti": "Dschibuti",
      "Dominica": "Dominica",
      "Dominican Republic": "Dominikanische Republik",
      "Ecuador": "Ecuador",
      "Egypt": "Ägypten",
      "El Salvador": "El Salvador",
      "Equatorial Guinea": "Äquatorialguinea",
      "Eritrea": "Eritrea",
      "Estonia": "Estland",
      "Ethiopia": "Äthiopien",
      "Falkland Islands (Malvinas)": "Falklandinseln (Malwinen)",
      "Faroe Islands": "Färöer",
      "Fiji": "Fidschi",
      "Finland": "Finnland",
      "France": "Frankreich",
      "French Guiana": "Französischuayana",
      "French Polynesia": "Französischolynesien",
      "French Southern Territories": "Französische Südund Antarktisgebiete",
      "Gabon": "Gabun",
      "Gambia": "Gambia",
      "Georgia": "Georgien",
      "Germany": "Deutschland",
      "Ghana": "Ghana",
      "Gibraltar": "Gibraltar",
      "Greece": "Griechenland",
      "Greenland": "Grönland",
      "Grenada": "Grenada",
      "Guadeloupe": "Guadeloupe",
      "Guam": "Guam",
      "Guatemala": "Guatemala",
      "Guernsey": "Guernsey",
      "Guinea": "Guinea",
      "Guinea-Bissau": "Guinea-Bissau",
      "Guineaissau": "Guineaissau",
      "Guyana": "Guyana",
      "Haiti": "Haiti",
      "Heard Island & Mcdonald Islands": "Heard und McDonaldinseln",
      "Holy See (Vatican City State)": "Heiliger Stuhl (Staat Vatikanstadt)",
      "Honduras": "Honduras",
      "Hong Kong": "Hongkong",
      "Hungary": "Ungarn",
      "Iceland": "Island",
      "India": "Indien",
      "Indonesia": "Indonesien",
      "Iran, Islamic Republic Of": "Iran, Islamische Republik",
      "Iraq": "Irak",
      "Ireland": "Irland",
      "Isle Of Man": "Isle of Man",
      "Israel": "Israel",
      "Italy": "Italien",
      "Jamaica": "Jamaika",
      "Japan": "Japan",
      "Jersey": "Jersey",
      "Jordan": "Jordanien",
      "Kazakhstan": "Kasachstan",
      "Kenya": "Kenia",
      "Kiribati": "Kiribati",
      "Korea": "Korea",
      "North Korea": "Nordkorea",
      "Kuwait": "Kuwait",
      "Kyrgyzstan": "Kirgisistan",
      "Lao People\"s Democratic Republic": "Demokratische Volksrepublik Laos",
      "Latvia": "Lettland",
      "Lebanon": "Libanon",
      "Lesotho": "Lesotho",
      "Liberia": "Liberia",
      "Libyan Arab Jamahiriya": "Libyschrabische Dschamahirija",
      "Liechtenstein": "Liechtenstein",
      "Lithuania": "Litauen",
      "Luxembourg": "Luxemburg",
      "Macao": "Macau",
      "Macedonia": "Mazedonien",
      "Madagascar": "Madagaskar",
      "Malawi": "Malawi",
      "Malaysia": "Malaysia",
      "Maldives": "Malediven",
      "Mali": "Mali",
      "Malta": "Malta",
      "Marshall Islands": "Marshallinseln",
      "Martinique": "Martinique",
      "Mauritania": "Mauretanien",
      "Mauritius": "Mauritius",
      "Mayotte": "Mayotte",
      "Mexico": "Mexiko",
      "Micronesia, Federated States Of": "Mikronesien, Föderierte Staaten von",
      "Moldova": "Moldawien",
      "Monaco": "Monaco",
      "Mongolia": "Mongolei",
      "Montenegro": "Montenegro",
      "Montserrat": "Montserrat",
      "Morocco": "Marokko",
      "Mozambique": "Mosambik",
      "Myanmar": "Myanmar",
      "Namibia": "Namibia",
      "Nauru": "Nauru",
      "Nepal": "Nepal",
      "Netherlands": "Niederlande",
      "Netherlands Antilles": "Niederländische Antillen",
      "New Caledonia": "Neukaledonien",
      "New Zealand": "Neuseeland",
      "Nicaragua": "Nicaragua",
      "Niger": "Niger",
      "Nigeria": "Nigeria",
      "Niue": "Niue",
      "Norfolk Island": "Norfolkinsel",
      "Northern Mariana Islands": "Nördliche Marianen",
      "Norway": "Norwegen",
      "Oman": "Oman",
      "Pakistan": "Pakistan",
      "Palau": "Palau",
      "Palestinian Territory, Occupied": "Palästinensische Gebiete",
      "Panama": "Panama",
      "Papua New Guinea": "Papuaeuguinea",
      "Paraguay": "Paraguay",
      "Peru": "Peru",
      "Philippines": "Philippinen",
      "Pitcairn": "Pitcairninseln",
      "Poland": "Polen",
      "Portugal": "Portugal",
      "Puerto Rico": "Puerto Rico",
      "Qatar": "Katar",
      "Reunion": "Réunion",
      "Romania": "Rumänien",
      "Russian Federation": "Russische Föderation",
      "Rwanda": "Ruanda",
      "Saint Barthelemy": "Saintarthélemy",
      "Saint Helena": "St. Helena",
      "Saint Kitts And Nevis": "St. Kitts und Nevis",
      "Saint Lucia": "St. Lucia",
      "Saint Martin": "Saintartin",
      "Saint Pierre And Miquelon": "Saintierre und Miquelon",
      "Saint Vincent And Grenadines": "St. Vincent und die Grenadinen",
      "Samoa": "Samoa",
      "San Marino": "San Marino",
      "Sao Tome And Principe": "São Tomé und Príncipe",
      "Saudi Arabia": "Saudirabien",
      "Senegal": "Senegal",
      "Serbia": "Serbien",
      "Seychelles": "Seychellen",
      "Sierra Leone": "Sierra Leone",
      "Singapore": "Singapur",
      "Slovakia": "Slowakei",
      "Slovenia": "Slowenien",
      "Solomon Islands": "Salomonen",
      "Somalia": "Somalia",
      "South Africa": "Südafrika",
      "South Georgia And Sandwich Isl": "Südgeorgien und die Südlichen Sandwichinseln",
      "Spain": "Spanien",
      "Sri Lanka": "Sri Lanka",
      "Sudan": "Sudan",
      "Suriname": "Suriname",
      "Svalbard And Jan Mayen": "Svalbard und Jan Mayen",
      "Swaziland": "Eswatini",
      "Sweden": "Schweden",
      "Switzerland": "Schweiz",
      "Syrian Arab Republic": "Arabische Republik Syrien",
      "Taiwan": "Taiwan",
      "Tajikistan": "Tadschikistan",
      "Tanzania": "Tansania",
      "Thailand": "Thailand",
      "Timoreste": "Timoreste",
      "Timor-Leste": "Osttimor",
      "Togo": "Togo",
      "Tokelau": "Tokelau",
      "Tonga": "Tonga",
      "Trinidad And Tobago": "Trinidad und Tobago",
      "Tunisia": "Tunesien",
      "Turkey": "Türkei",
      "Turkmenistan": "Turkmenistan",
      "Turks And Caicos Islands": "Turksund Caicosinseln",
      "Tuvalu": "Tuvalu",
      "Uganda": "Uganda",
      "Ukraine": "Ukraine",
      "United Arab Emirates": "Vereinigte Arabische Emirate",
      "United Kingdom": "Vereinigtes Königreich",
      "United States": "Vereinigte Staaten",
      "United States Outlying Islands": "Außengebiete der Vereinigten Staaten",
      "Uruguay": "Uruguay",
      "Uzbekistan": "Usbekistan",
      "Vanuatu": "Vanuatu",
      "Venezuela": "Venezuela",
      "Vietnam": "Vietnam",
      "Virgin Islands, British": "Britische Jungferninseln",
      "Virgin Islands, US": "Amerikanische Jungferninseln",
      "Wallis And Futuna": "Wallis und Futuna",
      "Western Sahara": "Westsahara",
      "Yemen": "Jemen",
      "Zambia": "Sambia",
      "Zimbabwe": "Simbabwe",
    },
    "region": "Region",
  },
  "_table": {
    "messages": {
      "new_lines": "Neue Zeilen",
      "removed_lines": "Entfernte Zeilen",
      "lastSeen": "Zuletzt gesehen",
      "lastUpdated": "Zuletzt aktualisiert",
      "total_lines": "Gesamtzahl der Zeilen",
      "new_in_filter": "Neu im Filter",
    },
    "filter": {
      "condition": {
        "_title": {
          "equals": "gleich",
          "notEquals": "nicht gleich",
          "greaterThan": "größer als",
          "lessThan": "weniger als",
          "between": "zwischen",
          "greaterThanOrEqual": "größer als order gleich",
          "lessThanOrEqual": "weniger als oder gleich",
          "contains": "enthält",
          "notContains": "enthält nicht",
          "startsWith": "beginnt mit",
          "endsWith": "endet mit",
          "in": "in",
          "not in": "nicht in",
          "empty": "leer",
          "not empty": "nicht leer",
          "Is": "ist"
        }
      }
    }
  },
  "_product-instance": {
    "state": "Status",
    "id": "Produktinstanz-ID",
    "createdAt": "Erstellungsdatum",
    "updatedAt": "Letzte Aktualisierung",
    "_state": {
      'produced': "Produziert",
      'shipping': "Versendet",
      'inStore': "Im Lager",
      'outOfStore': "Außerhalb vom Lager",
      'supportCase': "Supportfall",
      'customer': "Kunde",
      'stolen': "Gestohlen",
      'inTesting': "wird getestet",
      'tested': "getest",
      'repaired': 'repariert',
      "unknown": "Unbekannt",
      "complete": "Abgeschlossen",
      "inRepair": "In Reparatur",
      "sold": "Verkauft",
      "recycled": "Recycling",
      "defined": "Vordefiniert",
      "flashed": "geflasht",
    },
    "software_version": "Softwareversion",
    "key_box": "Schlüsselkiste",
    "quality": "Qualität",
    "_quality": {
      "unknown": "Unbekannt",
      "inProduction": "In Produktion",
      "recycled": "Recycling",
      "postProductionTested": "Post-Produktion getestet",
      "QualityA": "Qualität A",
      "SLA": "Second Live A",
      "SLB": "Second Live B",
      "SLC": "Second Live C",
      "tested": "Getestet",

    },
    "description": "Beschreibung",
    "_product": {
      "unique_name": "Eindeutiger Name",
      "version": "Version",
      "variant": "Variante",
    },
    "subState": "Unterstatus",
    "imei_1": "IMEI 1",
    "imei_2": "IMEI 2",
    "serial_number": "Seriennummer",
    "Description": "",
    "mac_bt": "MAC Bluetooth",
    "mac_wifi": "MAC WiFi",
    "mac_lan": "MAC LAN",
    "meid": "MEDI",
  },
  "_missingtranslation": {
    "id": "id",
    "key": "key",
    "counter": "counter",
    "language": "language",
    "last_hit": "last_hit",
  },
  "_customer": {
    "email": "Haupt E-Mail",
    "description": "@:description",
    "address_text": "Alte Adresse als Text - Format",
    "phone": "@:phone",
    "sales_contact_person": "Verkaufsberater",
    "office_contact_person": "Auftragsberater",
    "name": "Kundenname",
    "important_information": "Wichtige Informationen",
    "classification": "Klassifizierung",
    "category": "Kategorie",
    "customer_id": "Kundennummer",
    "emptyState": {
      "cannotFindCustomer": "Kann den Kunden mit dieser E-Mail nicht finden",
      "lookingForCustomer": "Suche zunächst im System nach einem Kunden, zu dem die E-Mail gehören soll, bevor du die Option zum Anlegen eines neuen Kunden wählst!",
      "lookingForCustomerSubtitle": "Zuerst den Kunden im System suchen und auswählen",
      "createCustomer": "Neuen Kunden anlegen"
    },
    "_category": {
      'Private Customer': 'Privatkunden',
      'Business Customer': "Geschäftskunden",
      'Distributor': "Vertrieb",
      'Government Agencies': 'Behörden / Ämter',
      'Educational Institutions': 'Bildungseinrichtungen',
      'Employee': 'Mitarbeiter',
      'Partner': 'Partner',
      'Prospect': 'Interessent'
    },
    "_addresses": {
      "street": "@:_address.street",
      "postcode": "@:_address.postcode",
      "city": "@:_address.city",
      "country_id": "@:_address.country_id"
    },
    "contacts": "Kontakte",
    "_contacts": {
      "_addresses": {
        "street": "@:_address.street",
        "postcode": "@:_address.postcode",
        "city": "@:_address.city",
        "country_id": "@:_address.country_id"
      },
      "_roles": {
        "defaultContact": "Standard Kontakt",
        "management": "Management",
        "sales": "Vertrieb",
        "marketing": "Marketing",
        "finance": "Finanzen",
        "it": "IT",
        "operations": "Betrieb",
        "procurement": "Einkauf",
        "legal": "Recht",
        "hr": "Personalwesen",
        "customer_support": "Kundendienst",
        "research_and_development": "Forschung und Entwicklung",
        "sustainability": "Nachhaltigkeit",
        "project_management": "Projektmanagement",
        "logistics": "Logistik",
        "consulting": "Beratung"
      },
      "first_name": "Vorname",
      "last_name": "Nachname",
      "description": "@:description",
      "title": "Titel",
      "gender": "Geschlecht",
      "roles": "Rollen",
      "_phones": {
        "title": "Telefonkontakt",
        "phone": "Telefonnummer",
        "types": "Typ",
        "_types": {
          "formImport": "Importiert vom Formular",
          "outdated": "veraltet",
          "primary": "primäre",
          "work": "Arbeit",
          "private": "Privat",
          'mobile': "Mobil",
          'landline': "Festnetz",
          'international': "International",
          'fax': "Fax",
        },
        "description": "Beschreibung"
      },
      "_emails": {
        "title": "E-Mail Kontakt",
        "email": "Email",
        "types": "Typ",
        "_types": {
          "formImport": "Importiert vom Formular",
          "outdated": "veraltet",
          "primary": "primäre",
          "work": "Arbeit",
          "private": "Privat",
        },
        "description": "Beschreibung",
        "newsletter": "Newsletter",
      },
      "btn": {
        "add": "Hinzufügen",
        "update": "Aktualisieren"
      }
    },
    "updatedAt": "zuletzt geändert",
    "company": "Firma",
    "gender": "Geschlecht",
    "title": "Titel",
    "createdAt": "erstellt am",
    "id": "Kunden ID (intern)",
  },
  "_template": {
    "id": "id",
    "name": "Name",
    "description": "Beschreibung",
    "notes": "Notizen",
    "note": "Notiz",
  },
  "_repairParts": {
    "repairParts": "Reparaturteile",
    "price": "Preis",
    "repairCost": "Reparaturkosten",
    "totalCost": "Gesamtkosten",
    "noTax": "Gesammtkosten (Netto):",
    "withTax": "Gesammtkosten mit Steuer (19%):",
  },
  "_defineHardware": {
    "OUI": "OUI",
    "TAC": "TAC",
    "MAC Start": "MAC Start",
    "IMEI Start": "IMEI Start",
    "Amount": "Amount",
    "Generate Wifi MAC": "Generate Wifi MAC",
    "Generate Bluetooth MAC": "Generate Bluetooth MAC",
    "Generate IMEI 1": "Generate IMEI 1",
    "Generate IMEI 2": "Generate IMEI 2",
    "Define": "Definieren",
    "check existence": "Existenz prüfen",
    "Checking product existence": "Überprüfung der bestehenden Produktinstanzen",
    "create": "Anlegen",
    "Existing Product": "Vorhandenes Produkt",
    "Creating hardware": "Erstelle Hardware...",
    "existing products": "Vorhandene ProdukteInstanzen",
    "Done": "Fertig",
    "Export": "Export",
  },
  "_tableConfig": {
    size: {
      title: 'Anzahl der Zeilen in einer Tabelle',
      description: 'Anzahl der Zeilen die standartmäßig in einer Tabelle angezeigt werden sollen'
    }
  },
  "_addressValidation": {
    "title": "Adressenprüfung",
    "noMatchText": "Keine Addresse wurde gefunden",
    "currentAddress": "Aktuell",
    "copyThisAddress": "Hinzufügen",
    "close": "Schließen",
  },
  "_mobileapp": {
    "main": {
      "testing": {
        "title": "Test-Ticket",
        "subtitle": "Scanne ein Gerät und führe direkt ein Test durch."
      },
      "shipping": {
        "title": "Versand-Ticket",
        "subtitle": "Erstelle und konfiguriere ein Versandticket direkt von deinem mobilen Gerät aus."
      },
      "return": {
        "title": "Rückgabe-Ticket",
        "subtitle": "Erstelle ein Rückgabe-Ticket für zurückgesendete Produkte."
      },
      "mobile": {
        "title": "Mobile-Aufruf",
        "subtitle": "Scanne einen QR-Code, um das entsprechende Gerät direkt auf deinem Mobilgerät zu öffnen."
      },
      "desktop": {
        "title": "Desktop-Aufruf",
        "subtitle": "Scanne ein Gerät, um es direkt in deinem Desktop-System (Intra) zu öffnen."
      },
      "autofill": {
        "title": "Autoausfüllen",
        "subtitle": "Scannen einen QR- oder Barcode und übertrage diesen direkt in das aktive Textfeld auf dem Desktop."
      }
    },
    "action": {
      "back": "Zurück",
      "cancelRequest": "Anfrage abbrechen",
    },
    "shippingInfo": {
      "title": "Bestellnummer Online-Shop",
      "text": "Der Online-Shop vergibt Auftragsnummern. Diese Nummer befindet sich auf der Rechnung.",
    },
    "autofillInfo": {
      "title": "Textfeld auswählen",
      "text": "Scanne ein Barcode oder QR-Code, um den Inhalt in das aktive Textfeld auf dem Desktop zu übertragen.",
    },
    "logoutState": {
      "title": "Bitte melde dich an!",
      "text": "In deinem Desktop-System (Intra) unter deinem Profil findest du einen QR-Code, um dich mit deinem mobilen Gerät einzuloggen.",
    },
    "createProductShipment": {
      "order": "Bestellung",
      "trackingCode": "Tracking-Code",
      "pleaseScanTrackingCode": "Tracking-Code bitte einscannen",
      "scannedProduct": "Gescannte Produkte",
      "pleaseScanProduct": "Bitte Produkt scannen",
      "magentoItems": "Bestellte Artikel",
      "noMagentoItems": "Keine Artikel gefunden",
    }
  },
  "_tableColumnConfig": {
    "changeColumnConfig": "Ändere Spaltenkonfiguration",
    "activeColumns": "Sichtbare Felder",
    "possibleColumns": "Alle verfügbare Felder",
  },
  "_TicketRelatedItem": {
    "createdAt": "Erstellt am",
    "subType": "Untertyp",
    "magentoOrderNumber": "Bestellnummer",
  },
  "_ticketItem": {
    "subTicketsOpen": "Sub-Tickets werden gezeigt",
    "subTicketsClosed": "Sub-Tickets sind versteckt",
  },
  "_organization": {
    "name": "Organisation",
    "vip": "VIP",
  }
} as any;

config._ticket._team._name = config._team
config["_product-instance"].tickets = config._ticket;
config["_magentoOrder"].addresses = config._address;
config["_ticket"]['_customer'] = config['_customer'];

const fixtConfig = config;

export default fixtConfig;